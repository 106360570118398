import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CmsRoutingModule } from './cms-routing.module';
import { LoginComponent } from './pages/core/login/login.component';
import { DashboardComponent } from './pages/core/dashboard/dashboard.component';
import { LoginFormComponent } from './components/core/login-form/login-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UserManagementComponent } from './pages/core/user-management/user-management.component';
import { UserListComponent } from './components/core/user-management/user-list/user-list.component';
import { UserAddComponent } from './components/core/user-management/user-add/user-add.component';
import { ModalComponent } from './components/core/shared/modal/modal.component';
import { BugreportComponent } from './pages/core/bugreport/bugreport.component';
import { BugReportFormComponent } from './components/core/bugreport/bugreport-form/bugreport-form.component';
import { CardComponent } from './components/core/shared/card/card.component';
import { SideNavComponent } from './components/core/shared/side-nav/side-nav.component';
import { NavComponent } from './components/core/shared/nav/nav.component';
import { UserProfileComponent } from './pages/core/user-profile/user-profile.component';
import { PasswordResetComponent } from './components/core/user-profile/password-reset/password-reset.component';
import { ProfileDetailsComponent } from './components/core/user-profile/profile-details/profile-details.component';
import { TotalUsersComponent } from './components/core/analytics/total-users/total-users.component';
import { CmsLayoutComponent } from './layouts/cms-layout/cms-layout.component';
import { LogsComponent } from './pages/core/logs/logs.component';
import { HighlightModule } from 'ngx-highlightjs';
import { AddUploadButtonComponent } from './components/core/shared/add-upload-button/add-upload-button.component';
import { LanguageBarComponent } from './components/core/shared/language-bar/language-bar.component';
import { TitleDirective } from './directives/core/title.directive';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipe } from '../shared/pipes/filter.pipe';
import { TagsComponent } from './pages/content/tags/tags.component';
import { PublicationsComponent } from './pages/content/publications/publications.component';
import { ContentComponent } from './pages/content/content/content.component';
import { TagNavComponent } from './components/content/tag-nav/tag-nav.component';
import { SingleTagComponent } from './components/content/single-tag/single-tag.component';
import { SinglePublicationComponent } from './components/content/single-publication/single-publication.component';
import { AddPublicationComponent } from './components/content/add-publication/add-publication.component';
import { SingleContentComponent } from './components/content/single-content/single-content.component';

@NgModule({
  declarations: [
    LoginComponent,
    DashboardComponent,
    LoginFormComponent,
    UserManagementComponent,
    UserListComponent,
    UserAddComponent,
    ModalComponent,
    BugreportComponent,
    BugReportFormComponent,
    CardComponent,
    SideNavComponent,
    NavComponent,
    UserProfileComponent,
    PasswordResetComponent,
    ProfileDetailsComponent,
    TotalUsersComponent,
    CmsLayoutComponent,
    LogsComponent,
    AddUploadButtonComponent,
    LanguageBarComponent,
    TitleDirective,
    FilterPipe,
    TagsComponent,
    PublicationsComponent,
    ContentComponent,
    TagNavComponent,
    SingleTagComponent,
    SinglePublicationComponent,
    AddPublicationComponent,
    SingleContentComponent
  ],
  imports: [
    EditorModule,
    CommonModule,
    CmsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HighlightModule,
    LazyLoadImageModule,
    TranslateModule,
    AngularMultiSelectModule
  ],
  providers: [FilterPipe]
})
export class CmsModule {}
