import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Tag } from '../../../models/content/tag';

@Component({
  selector: 'app-single-tag',
  templateUrl: './single-tag.component.html',
  styleUrls: ['./single-tag.component.styl']
})
export class SingleTagComponent implements OnInit {
  public tagForm: FormGroup;
  @Input() public tag: Tag;
  @Output() public delete = new EventEmitter();
  @Output() public update = new EventEmitter();
  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.tagForm = this.formBuilder.group(
        {
          tag :['',[Validators.required]]
        }
    );
  }

  onSave(){
    this.update.emit({id: this.tag.id, tag: this.tagForm.controls.tag.value});
  }

  onDelete(){
    this.delete.emit(this.tag.id);
  }

}
