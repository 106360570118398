<section class="container">
    <section class="img"
             [style.background-image]="'url(https://upfoundation.fra1.digitaloceanspaces.com/posts/' + post.coverImageUrl + ')'">
        <h1>{{this.post.title}}</h1>
        <button *ngIf="!post.published" (click)="publish()">publish</button>
        <svg (click)="onDelete()" class="edit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.8 14.8">
            <defs>
                <style>.cls-1 {
                    fill: #85CDDB;
                }

                .cls-2 {
                    fill: #fff;
                }</style>
            </defs>
            <title>delete_icon</title>
            <g id="Ebene_2" data-name="Ebene 2">
                <g id="landing_page" data-name="landing page">
                    <circle class="cls-1" cx="7.4" cy="7.4" r="7.4"/>
                    <path class="cls-2"
                          d="M10.19,4.4H8.74a1,1,0,0,0-1-.8H7a1,1,0,0,0-1,.8H4.61a.44.44,0,0,0-.48.39.45.45,0,0,0,.48.4H4.7l.61,5.57a.5.5,0,0,0,.5.44H9a.5.5,0,0,0,.5-.44l.61-5.57h.09a.45.45,0,0,0,.48-.4A.44.44,0,0,0,10.19,4.4Zm-4.3,6.51a.15.15,0,0,1-.15-.14l-.44-5a.16.16,0,0,1,.15-.16h.08a.15.15,0,0,1,.15.14l.36,5A.15.15,0,0,1,5.89,10.91ZM7,3.85h.76a.74.74,0,0,1,.7.55H6.32A.74.74,0,0,1,7,3.85Zm-.38,7.06h0a.15.15,0,0,1-.15-.14l-.23-5a.14.14,0,0,1,.15-.16h.08a.15.15,0,0,1,.15.15l.15,5A.14.14,0,0,1,6.64,10.91Zm.91-.15a.15.15,0,0,1-.15.15h0a.15.15,0,0,1-.15-.15l0-5a.15.15,0,0,1,.15-.15h.08a.15.15,0,0,1,.15.15Zm.76,0a.15.15,0,0,1-.15.14h0A.16.16,0,0,1,8,10.75l.16-5a.15.15,0,0,1,.15-.15h.08a.14.14,0,0,1,.15.16Zm.75,0a.15.15,0,0,1-.15.14.15.15,0,0,1-.15-.16l.36-5a.15.15,0,0,1,.15-.14h.08a.16.16,0,0,1,.15.16Z"/>
                </g>
            </g>
        </svg>

        <svg (click)="duplicate()" class="duplicate" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.8 14.8"><title>duplicate_icon</title><g id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><circle cx="7.4" cy="7.4" r="7.4" style="fill:#85CDDB"/><polygon points="11.3 4.35 4.31 4.35 4.31 5.06 10.59 5.06 10.59 9.64 11.3 9.64 11.3 4.35" style="fill:#fff"/><path d="M3.5,5.16v5.29h7V5.16ZM8.24,7.94H7.15V9.08H6.84V7.94H5.76V7.65H6.84V6.52h.31V7.65H8.24Z" style="fill:#fff"/></g></g></svg>

        <a [routerLink]="['/cms/post/' + post.id]">
            <svg  class="delete" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.97 211.97">
                <defs>
                    <style>.cls-1 {
                        fill: #85CDDB;
                    }

                    .cls-2 {
                        fill: #fff;
                    }</style>
                </defs>
                <title>editicon</title>
                <g id="Ebene_2" data-name="Ebene 2">
                    <g id="Background">
                        <circle class="btn" cx="105.99" cy="105.99" r="105.99"/>
                        <g id="_9xRGOz.tif" data-name="9xRGOz.tif">
                            <path class="cls-2" d="M83.12,141.71,70.26,128.85,126,73.09,138.87,86Z"/>
                            <path class="cls-2" d="M148.49,76.42l-7.17,7.15-12.9-12.92,7.17-7.16Z"/>
                            <path class="cls-2" d="M80.57,144.24l-17.08,4.24,4.23-17.11Z"/>
                        </g>
                    </g>
                </g>
            </svg>
        </a>
    </section>
</section>
