import { OnInit } from '@angular/core';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
var PostDetailComponent = /** @class */ (function () {
    function PostDetailComponent(postService, route, router, sanitized, location, metaService, titleService) {
        this.postService = postService;
        this.route = route;
        this.router = router;
        this.sanitized = sanitized;
        this.location = location;
        this.metaService = metaService;
        this.titleService = titleService;
    }
    Object.defineProperty(PostDetailComponent.prototype, "fullBody", {
        get: function () {
            return this.sanitized.bypassSecurityTrustHtml(this.post.body);
        },
        enumerable: true,
        configurable: true
    });
    PostDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params
            .pipe(switchMap(function (e) { return _this.postService.getPost(e.id); }), take(1))
            .subscribe(function (e) {
            _this.post = e;
            _this.metaService.updateTag({
                property: 'og:image',
                content: "https://upfoundation.fra1.digitaloceanspaces.com/posts/" + e.coverImageUrl
            });
            _this.metaService.updateTag({ property: 'og:title', content: e.title });
            _this.metaService.updateTag({ name: 'title', content: "UP_Foundation Blog : " + e.title });
            _this.metaService.updateTag({
                property: 'og:url',
                content: environment.api + _this.router.url
            });
            _this.titleService.setTitle("UP_Foundation Blog : " + e.title);
        });
    };
    PostDetailComponent.prototype.back = function (e) {
        e.preventDefault();
        this.location.back();
    };
    return PostDetailComponent;
}());
export { PostDetailComponent };
