/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./publication-card.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./publication-card.component";
var styles_PublicationCardComponent = [i0.styles];
var RenderType_PublicationCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PublicationCardComponent, data: {} });
export { RenderType_PublicationCardComponent as RenderType_PublicationCardComponent };
export function View_PublicationCardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 15, "article", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "card-head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵppd(9, 1), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 1), (_l()(), i1.ɵted(-1, null, ["lire plus"])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "div", [["class", "image"]], [[4, "background-image", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_5 = _ck(_v, 14, 0, ("/post/" + _co.post.id)); _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.post.title; _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.post.publishDate)); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.post.excerpt; _ck(_v, 11, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 13).target; var currVal_4 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_3, currVal_4); var currVal_6 = (("url(https://upfoundation.fra1.digitaloceanspaces.com/posts/" + _co.post.coverImageUrl) + ")"); _ck(_v, 16, 0, currVal_6); }); }
export function View_PublicationCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-publication-card", [], null, null, null, View_PublicationCardComponent_0, RenderType_PublicationCardComponent)), i1.ɵdid(1, 114688, null, 0, i4.PublicationCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PublicationCardComponentNgFactory = i1.ɵccf("app-publication-card", i4.PublicationCardComponent, View_PublicationCardComponent_Host_0, { post: "post" }, {}, []);
export { PublicationCardComponentNgFactory as PublicationCardComponentNgFactory };
