import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./cms/cms.module.ngfactory").then(function (module) { return module.CmsModuleNgFactory; }); }, ɵ1 = function () { return import("./frontend/frontend.module.ngfactory").then(function (module) { return module.FrontendModuleNgFactory; }); };
var routes = [
    {
        path: 'cms',
        loadChildren: ɵ0
    },
    {
        path: '',
        loadChildren: ɵ1,
        pathMatch: 'full'
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1 };
