<section>
    <form [formGroup]="tagForm">
        <label>
            <input placeholder="tag name" [value]="tag.tag" formControlName="tag">
        </label>
        <button class="save" type="button" (click)="onSave()">Save</button>
        <span>|</span>
        <button class="delete" type="button" (click)="onDelete()">Delete</button>
    </form>
</section>
