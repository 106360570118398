import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bugreport',
  templateUrl: './bugreport.component.html',
  styleUrls: ['./bugreport.component.styl']
})
export class BugreportComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
