import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { RegisterDto, User } from './user.model';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private httpClient: HttpClient) {}
  public getAll(): Observable<User[]> {
    return this.httpClient.get<User[]>(environment.api + '/api/user/').pipe(take(1));
  }
  public delete(id: number) {
    return this.httpClient
      .delete(`/api/user/${id}`)
      .pipe(take(1))
      .subscribe();
  }
  public create(userDto: RegisterDto) {
    return this.httpClient.post(environment.api + '/api/auth/register/', userDto).pipe(take(1));
  }
}
