<div>
  <h2>Add new user</h2>
	<form [formGroup]="userAddFrom" (submit)="onSubmit()">
		<label>
      Username
			<input type="text" formControlName="username" class="cms-input"/>
			<br />
		</label>
		<label>
			First Name
			<input type="text" formControlName="firstName" class="cms-input"/>
			<br />
		</label>
		<label>
			Last Name
			<input type="text" formControlName="lastName" class="cms-input"/>
			<br />
		</label>
		<label>
			Password
			<input type="password" autocomplete="current-password" formControlName="password" class="cms-input"/>
			<br />
		</label>
		<label>
			Email Address
			<input type="email" formControlName="emailAddress" class="cms-input"/>
			<br />
		</label>
		<label>
			Must Change Password
			<input type="checkbox" formControlName="mustChangePassword" class="cms-input"/>
		</label>
		<button type="submit" class="cms-button-text-primary">Submit</button>
	</form>
</div>
