import { take } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BugReportService = /** @class */ (function () {
    function BugReportService(httpClient) {
        this.httpClient = httpClient;
    }
    BugReportService.prototype.sendReport = function (report) {
        this.httpClient
            .post(environment.api + '/api/bug-report', report)
            .pipe(take(1))
            .subscribe();
    };
    BugReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BugReportService_Factory() { return new BugReportService(i0.ɵɵinject(i1.HttpClient)); }, token: BugReportService, providedIn: "root" });
    return BugReportService;
}());
export { BugReportService };
