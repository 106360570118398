import { OnInit } from '@angular/core';
var BlogComponent = /** @class */ (function () {
    function BlogComponent(postService, tagService, route) {
        this.postService = postService;
        this.tagService = tagService;
        this.route = route;
        this.limit = 8;
        this.filter = '';
        this.search = '';
    }
    Object.defineProperty(BlogComponent.prototype, "visiblePosts", {
        get: function () {
            var _this = this;
            if (!this.posts)
                return;
            return this.posts
                .filter(function (e) { return e.published; })
                .filter(function (e) { return _this.filter === '' || e.tags.filter(function (tag) { return tag.tag === _this.filter; }).length > 0; })
                .filter(function (e) { return _this.search === '' || e.title.toLowerCase().includes(_this.search.toLowerCase()); });
        },
        enumerable: true,
        configurable: true
    });
    BlogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.postService.getPosts().subscribe(function (e) { return (_this.posts = e); });
        this.tagService.getTags().subscribe(function (e) { return (_this.tags = e); });
        this.route.params.subscribe(function (e) { return (_this.filter = e.filter !== undefined ? e.filter : ''); });
    };
    return BlogComponent;
}());
export { BlogComponent };
