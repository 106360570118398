<section *ngIf="post">
    <form (submit)="save()" [formGroup]="postForm">
        <div class="left-container">
            <label>
                <input type="text" placeholder="Title" [value]="post ? post.title : ''" formControlName="title">
            </label>
            <div class="container">
                <section class="img"
                         [style.background-image]="previewUrl ? 'url(' + previewUrl + ')': 'url(https://upfoundation.fra1.digitaloceanspaces.com/posts/' + post.coverImageUrl + ')'">
                    <svg (click)="file.click()" xmlns="http://www.w3.org/2000/svg" width="74.78mm" height="74.78mm"
                         viewBox="0 0 211.97 211.97">
                        <defs>
                            <style>.cls-1 {
                                fill: #85CDDB;
                            }

                            .cls-2 {
                                fill: #fff;
                            }</style>
                        </defs>
                        <title>editicon</title>
                        <g id="Ebene_2" data-name="Ebene 2">
                            <g id="Background">
                                <circle class="btn" cx="105.99" cy="105.99" r="105.99"/>
                                <g id="_9xRGOz.tif" data-name="9xRGOz.tif">
                                    <path class="cls-2" d="M83.12,141.71,70.26,128.85,126,73.09,138.87,86Z"/>
                                    <path class="cls-2" d="M148.49,76.42l-7.17,7.15-12.9-12.92,7.17-7.16Z"/>
                                    <path class="cls-2" d="M80.57,144.24l-17.08,4.24,4.23-17.11Z"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <input (change)="uploadEventHandler($event)" type="file" style="display: none" #file>
                </section>
            </div>

            <label>
                <textarea class="short" maxlength="200" placeholder="Short description" formControlName="excerpt"
                          [value]="post ? post.excerpt : ''"></textarea>
            </label>
            <label>
                <input formControlName="publishedDate" [value]="post.publishDate" type="date">
            </label>
            <div class="publish">
                <h4>Publish</h4>
                <div class="btn">
                    <label class="switch">
                        <input formControlName="published" type="checkbox" [value]="post.published"
                               [checked]="post.published">
                        <span class="slider"></span>
                    </label>
                </div>
            </div>
            <angular2-multiselect [(ngModel)]="selectedTags" [ngModelOptions]="{standalone: true}"
                                  [settings]="dropdownSettings" [data]="tags" required>
            </angular2-multiselect>


        </div>
        <div class="right-container">
            <editor [(ngModel)]="content" apiKey="60v94r4b3irowaff5u3fkkajnwu6c249go7hj3s14gw051yz"
                    [init]="tinyConfig" [ngModelOptions]="{standalone: true}"></editor>
            <label>
                <input class="lien" formControlName="link" type="text" placeholder="link"
                       [value]="post ? post.linkedArticle : ''">
                <svg class="linksvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.8 68.79">
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                            <path d="M67.89,15.92,53.36,1.4a1.78,1.78,0,0,0-.26-.22,1.78,1.78,0,0,0-.22-.26,3.12,3.12,0,0,0-4.43,0L34.4,15a3.14,3.14,0,1,0,4.43,4.44L50.67,7.57,61.24,18.14,38.31,41.07l-5.05-5a3.13,3.13,0,1,0-4.43,4.43L36,47.62l0,0,0,.05a3.12,3.12,0,0,0,4.43,0l27.21-27.2.07-.09.09-.07A3.14,3.14,0,0,0,67.89,15.92Z"/>
                            <path d="M30,49.38,18.14,61.22,7.57,50.65,30.49,27.72l5.05,5.05A3.14,3.14,0,0,0,40,28.33l-7.16-7.16-.06,0,0-.06a3.14,3.14,0,0,0-4.43,0L1.07,48.28a.5.5,0,0,1-.07.08.5.5,0,0,1-.08.07,3.13,3.13,0,0,0,0,4.44L15.44,67.39c.08.08.18.14.26.21s.14.19.22.27a3.14,3.14,0,0,0,4.43,0L34.41,53.81A3.13,3.13,0,1,0,30,49.38Z"/>
                        </g>
                    </g>
                </svg>
            </label>
            <button class="save" type="submit">Save</button>
        </div>
    </form>
</section>
