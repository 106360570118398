import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Tag } from '../../../models/content/tag';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  constructor(private httpClient: HttpClient) {}
  public getTags(): Observable<Tag[]> {
    return this.httpClient.get<Tag[]>(`${environment.api}/api/tag/`);
  }
  public updateTag(tag: Tag): Observable<Tag> {
    return this.httpClient.put<Tag>(`/api/tag/`, tag);
  }
  public createTag(tag: Tag): Observable<Tag> {
    return this.httpClient.post<Tag>(`${environment.api}/api/tag/`, tag);
  }
  public deleteTag(id: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`/api/tag/${id}`);
  }
  public listifyTags(tags: Tag[]) {
    if (tags === undefined) return [];
    return tags.map(item => {
      return { id: item.id, itemName: item.tag };
    });
  }
}
