<section >
    <ul>
        <li class="clear-button" *ngIf="filter !== ''">
            <a [routerLink]="['/posts',{filter : ''}]">Supprimer</a>
        </li>
        <li *ngFor="let tag of tags">
            <a [ngClass]="filter === tag.tag ? 'active' : ''" [routerLink]="['/posts',{filter : tag.tag}]">{{tag.tag}}</a>
        </li>
    </ul>
    <div class="search-bar">
        <label>
            <input type="text" [(ngModel)]="search" placeholder="Recherche...">
        </label>
        <svg viewBox="0 0 21.66 21.66" width="21.66" height="21.66" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1 { fill: #85CDDB; }</style></defs><g data-name="Layer 2" id="Layer_2"><g data-name="Layer 1" id="Layer_1-2"><path class="cls-1" d="M10.46,13.46l-1.3-1.29a5.42,5.42,0,1,1,3-3l1.3,1.29a7.13,7.13,0,1,0-3,3Z"></path><path class="cls-1" d="M4,.72,5.27,2.06A5.41,5.41,0,1,1,2.18,5L.92,3.63A7.13,7.13,0,1,0,4,.72Z"></path><rect class="cls-1" height="13.2" transform="translate(-6.58 15.87) rotate(-45)" width="3.17" x="14.28" y="9.28"></rect></g></g></svg>
        <span class="close" *ngIf="search !== ''" (click)="search = ''">X</span>
    </div>
    <div class="article-parent">
        <app-publication-card *ngFor="let post of visiblePosts" [post]="post" ></app-publication-card>
        <div class="transparent"></div>
        <div class="transparent"></div>
    </div>
</section>
