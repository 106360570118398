<section>
    <div class="add-category">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140.22 140.22">
                <title>addbutton</title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path class="btn"
                              d="M70.11,0a70.11,70.11,0,1,0,70.11,70.11A70.11,70.11,0,0,0,70.11,0Zm38.64,71.89H71.89v36.86H68.33V71.89H31.47V68.33H68.33V31.47h3.56V68.33h36.86Z"/>
                    </g>
                </g>
            </svg>
    </div>
</section>
