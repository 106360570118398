import { Component, OnInit } from '@angular/core';
import { LogsService } from '../../../services/core/dashboard/logs/logs.service';
import { Observable } from 'rxjs';
import { Log } from '../../../models/core/log';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.styl']
})
export class LogsComponent implements OnInit {
  private allLogs: Log[];
  private selectedType: string;
  private itemsPerPage = 5;
  private page = 0;
  constructor(private logService: LogsService) {}
  public getQueryType(str: string) {
    return str.split(' ')[0];
  }
  get logs() {
    if (this.selectedType !== 'null' && this.allLogs !== undefined) {
      return this.allLogs.filter(e => {
        e.type = this.selectedType;
      });
    }
    return this.allLogs;
  }

  public setFilter(event) {
    this.selectedType = event.target.value;
  }

  ngOnInit() {
    this.logService.getLogs(this.page, this.itemsPerPage).subscribe(logs => (this.allLogs = logs));
  }
}
