import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
var TitleService = /** @class */ (function () {
    function TitleService(titleService) {
        this.titleService = titleService;
        this.privateTitle = '';
    }
    Object.defineProperty(TitleService.prototype, "title", {
        get: function () {
            return this.privateTitle;
        },
        set: function (value) {
            this.privateTitle = value;
            this.titleService.setTitle("Bildung eemol anescht CMS -" + value);
        },
        enumerable: true,
        configurable: true
    });
    TitleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TitleService_Factory() { return new TitleService(i0.ɵɵinject(i1.Title)); }, token: TitleService, providedIn: "root" });
    return TitleService;
}());
export { TitleService };
