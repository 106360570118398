/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./post-detail.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./post-detail.component";
import * as i4 from "../../../cms/services/content/post/post.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/platform-browser";
var styles_PostDetailComponent = [i0.styles];
var RenderType_PostDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PostDetailComponent, data: {} });
export { RenderType_PostDetailComponent as RenderType_PostDetailComponent };
function View_PostDetailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Source"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.post.linkedArticle; _ck(_v, 1, 0, currVal_0); }); }
function View_PostDetailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "header"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Retour"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "post-body"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostDetailComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.post.linkedArticle; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(https://upfoundation.fra1.digitaloceanspaces.com/posts/" + _co.post.coverImageUrl) + ")"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.post.title; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.fullBody; _ck(_v, 6, 0, currVal_2); }); }
export function View_PostDetailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostDetailComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.post; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PostDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-post-detail", [], null, null, null, View_PostDetailComponent_0, RenderType_PostDetailComponent)), i1.ɵdid(1, 114688, null, 0, i3.PostDetailComponent, [i4.PostService, i5.ActivatedRoute, i5.Router, i6.DomSanitizer, i2.Location, i6.Meta, i6.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PostDetailComponentNgFactory = i1.ɵccf("app-post-detail", i3.PostDetailComponent, View_PostDetailComponent_Host_0, {}, {}, []);
export { PostDetailComponentNgFactory as PostDetailComponentNgFactory };
