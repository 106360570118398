<section>
  <form [formGroup]="loginForm" (ngSubmit)="submit()" class="login-container" method="post" accept-charset="utf-8">
    <div class="inputGroup">
      <div class="input-group mb-3">

        <input
          required=""
          type="text"
          formControlName="username"
          class="form-control"
          placeholder="USERNAME"
          aria-label="Username"
          aria-describedby="basic-addon1"
          data-kwimpalastatus="alive"
          data-kwimpalaid="1575532853039-3"
        />
        <div class="input-group mb-3">
          <div class="input-group-prepend"></div>
          <input
            required=""
            type="password"
            formControlName="password"
            class="form-control"
            placeholder="PASSWORD"
            aria-label="password"
            aria-describedby="basic-addon1"
            data-kwimpalastatus="alive"
            data-kwimpalaid="1575532853039-2"
          />
          <input type="submit" value="LOGIN" id="login-button" [disabled]="loginForm.invalid"/>
        </div>
      </div>
      <small>© Copyright 2019 by edutec.lu. All rights reserved.</small>
    </div>
  </form>
</section>
