import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Post } from '../../../cms/models/content/post';
import { PostService } from '../../../cms/services/content/post/post.service';

@Component({
  selector: 'app-publications-preview',
  templateUrl: './publications-preview.component.html',
  styleUrls: ['./publications-preview.component.styl'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('.5s ease-out', style({ opacity: '1' }))
      ])
    ])
  ]
})
export class PublicationsPreviewComponent implements OnInit {
  public backgroundPosition: number;
  public posts: Post[];

  constructor(private postService: PostService) {}

  ngOnInit() {
    this.setNavShapePosition();
    this.postService
      .getPostsPaginate()
      .pipe(take(1))
      .subscribe(e => (this.posts = e));
  }

  get visiblePosts() {
    if (!this.posts) return [];
    return this.posts.filter(e => e.published);
  }

  private setNavShapePosition() {
    if (typeof window === 'undefined') {
      return;
    }

    const sectionPosition: number = document.querySelector('.first-section').getBoundingClientRect()
      .top;
    this.backgroundPosition = sectionPosition * -0.9 + 125;
  }

  @HostListener('window:resize', [])
  public onResize(): void {
    this.setNavShapePosition();
  }

  // Scroll Listener
  @HostListener('window:scroll', [])
  public onScroll(): void {
    this.setNavShapePosition();
  }
}
