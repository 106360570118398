<section appTitle pageTitle="Publications"></section>
<section>
    <div class="left-container">
        <div class="search">
            <input [(ngModel)]="searchInput" type="text" placeholder="search">
            <svg class="research" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.66 21.66">
                <defs>
                    <style>.cls-1 {
                        fill: #85CDDB;
                    }</style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path class="cls-1"
                              d="M10.46,13.46l-1.3-1.29a5.42,5.42,0,1,1,3-3l1.3,1.29a7.13,7.13,0,1,0-3,3Z"/>
                        <path class="cls-1"
                              d="M4,.72,5.27,2.06A5.41,5.41,0,1,1,2.18,5L.92,3.63A7.13,7.13,0,1,0,4,.72Z"/>
                        <rect class="cls-1" x="14.28" y="9.28" width="3.17" height="13.2"
                              transform="translate(-6.58 15.87) rotate(-45)"/>
                    </g>
                </g>
            </svg>
        </div>
    </div>

    <div class="right-container">
        <app-add-publication (click)="createPublication()"></app-add-publication>
        <app-single-publication (delete)="onPublicationDelete()" *ngFor="let post of searchedPosts()" [post]="post" ></app-single-publication>
    </div>
</section>
