<section *ngIf="post">
    <div class="header" [style.background-image]="'url(https://upfoundation.fra1.digitaloceanspaces.com/posts/' + post.coverImageUrl + ')'">
        <a (click)="back($event)">Retour</a>
        <h1>{{post.title}}</h1>
    </div>
    <div class="post-body" [innerHTML]="fullBody">

    </div>
    <div *ngIf="post.linkedArticle">
        <a [href]="post.linkedArticle" target="_blank">Source</a>
    </div>
</section>

