<nav>
  <ul>
    <li>
      <a [routerLink]="['/cms/dashboard']" routerLinkActive="active">
        {{'cms.navigation.dashboard' | translate}}
      </a>
    </li>
    <li>
      <a [routerLink]="['/cms/tags']" routerLinkActive="active">
        Tags
      </a>
    </li>
    <li>
      <a [routerLink]="['/cms/publications']" routerLinkActive="active">
        Publications
      </a>
    </li>
  </ul>
  <div class="border"></div>
</nav>
