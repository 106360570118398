/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./add-publication.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./add-publication.component";
var styles_AddPublicationComponent = [i0.styles];
var RenderType_AddPublicationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddPublicationComponent, data: {} });
export { RenderType_AddPublicationComponent as RenderType_AddPublicationComponent };
export function View_AddPublicationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "add-category"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, ":svg:svg", [["viewBox", "0 0 140.22 140.22"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, ":svg:title", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["addbutton"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, ":svg:g", [["data-name", "Layer 2"], ["id", "Layer_2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:g", [["data-name", "Layer 1"], ["id", "Layer_1-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:path", [["class", "btn"], ["d", "M70.11,0a70.11,70.11,0,1,0,70.11,70.11A70.11,70.11,0,0,0,70.11,0Zm38.64,71.89H71.89v36.86H68.33V71.89H31.47V68.33H68.33V31.47h3.56V68.33h36.86Z"]], null, null, null, null, null))], null, null); }
export function View_AddPublicationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-add-publication", [], null, null, null, View_AddPublicationComponent_0, RenderType_AddPublicationComponent)), i1.ɵdid(1, 114688, null, 0, i2.AddPublicationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddPublicationComponentNgFactory = i1.ɵccf("app-add-publication", i2.AddPublicationComponent, View_AddPublicationComponent_Host_0, {}, {}, []);
export { AddPublicationComponentNgFactory as AddPublicationComponentNgFactory };
