/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/navigation/navigation.component.ngfactory";
import * as i3 from "../../components/navigation/navigation.component";
import * as i4 from "@angular/router";
import * as i5 from "../../components/footer/footer.component.ngfactory";
import * as i6 from "../../components/footer/footer.component";
import * as i7 from "./main.component";
var styles_MainComponent = [i0.styles];
var RenderType_MainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainComponent, data: {} });
export { RenderType_MainComponent as RenderType_MainComponent };
export function View_MainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation", [], null, null, null, i2.View_NavigationComponent_0, i2.RenderType_NavigationComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavigationComponent, [i4.Router], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-footer", [], null, null, null, i5.View_FooterComponent_0, i5.RenderType_FooterComponent)), i1.ɵdid(5, 114688, null, 0, i6.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_MainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main", [], null, null, null, View_MainComponent_0, RenderType_MainComponent)), i1.ɵdid(1, 114688, null, 0, i7.MainComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MainComponentNgFactory = i1.ɵccf("app-main", i7.MainComponent, View_MainComponent_Host_0, {}, {}, []);
export { MainComponentNgFactory as MainComponentNgFactory };
