<app-modal>
  <app-user-add (userAdded)="userAdded()"></app-user-add>
</app-modal>
<section appTitle pageTitle="Users">
  <table>
    <thead>
    <tr>
      <th>{{'cms.common.username' | translate}}</th>
      <th>E-Mail</th>
      <th>{{'cms.core.usermanagement.lastlogin' | translate}}</th>
      <th>{{'cms.core.usermanagement.activated' | translate}}</th>
      <th>{{'cms.core.usermanagement.role' | translate}}</th>
      <th>{{'cms.core.usermanagement.actions' | translate}}</th>
    </tr>
    </thead>
    <tr *ngFor="let user of users$ | async">
      <td align="center" >{{ user.username }}</td>
      <td align="center">{{ user.emailAddress }}</td>
      <td align="center" >{{ user.lastLogin | date: 'medium' }}</td>
      <label class="switch">
        <input type="checkbox">
        <span class="slider"></span>
      </label>
      <td align="center" >{{ user.role }}</td>
      <td class="actions">
        <button (click)="delete(user.id)">{{'cms.common.actions.delete' | translate}}</button>
      </td>
    </tr>
  </table>

  <button (click)="openModal()">
    {{'cms.core.usermanagement.addUser' | translate}}
  </button>
</section>

