import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.styl']
})
export class LandingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
