<section id="home" class="publications-preview">
    <div class="first-section"
         [ngStyle]="{backgroundPosition: 'bottom '+ backgroundPosition +'px center'}">
        <div class="slogan-desktop">
            <img [ngStyle]="{marginTop: -backgroundPosition + 'px'}" src="/assets/img/landing/bildunglogo.svg" alt="slogan">
            <p>
                Le blog de la UP_FOUNDATION, en partenariat avec EduTec, propose régulièrement des activités amusantes, des idées créatives et des réflexions intéressantes autour de l'éducation.
            </p>
        </div>

        <div class="slogan-mobile">
            <img src="/assets/img/landing/bildunglogomobile.svg" alt="slogan">
        </div>
        <div class="mobile-logos">
            <img src="/assets/img/landing/foundationmobile.svg" alt="foundationlogo">
            <img src="/assets/img/landing/edutecmobile.svg" alt="eduteclogo">
        </div>
    </div>
    <div class="second-section">
        <div class="article-parent">
            <app-publication-card  *ngFor="let post of visiblePosts" [post]="post" @fadeIn></app-publication-card>
            <div class="publication-footer">
                <img class="publication-title" src="/assets/img/landing/publication.svg" alt="title">
                <button class="see-more" [routerLink]="['/posts']">Voir plus</button>
            </div>
        </div>
    </div>
</section>
