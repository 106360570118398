import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Post } from '../../../cms/models/content/post';
import { PostService } from '../../../cms/services/content/post/post.service';

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.styl']
})
export class PostDetailComponent implements OnInit {
  public post: Post;

  constructor(
    private postService: PostService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitized: DomSanitizer,
    private location: Location,
    private metaService: Meta,
    private titleService: Title
  ) {}

  public get fullBody() {
    return this.sanitized.bypassSecurityTrustHtml(this.post.body);
  }

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap(e => this.postService.getPost(e.id)),
        take(1)
      )
      .subscribe(e => {
        this.post = e;
        this.metaService.updateTag({
          property: 'og:image',
          content: `https://upfoundation.fra1.digitaloceanspaces.com/posts/${e.coverImageUrl}`
        });
        this.metaService.updateTag({ property: 'og:title', content: e.title });
        this.metaService.updateTag({ name: 'title', content: `UP_Foundation Blog : ${e.title}` });
        this.metaService.updateTag({
          property: 'og:url',
          content: environment.api + this.router.url
        });
        this.titleService.setTitle(`UP_Foundation Blog : ${e.title}`);
      });
  }

  public back(e) {
    e.preventDefault();
    this.location.back();
  }
}
