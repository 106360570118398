import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Post } from '../../../models/content/post';
import { PostService } from '../../../services/content/post/post.service';

@Component({
  selector: 'app-single-publication',
  templateUrl: './single-publication.component.html',
  styleUrls: ['./single-publication.component.styl']
})
export class SinglePublicationComponent implements OnInit {
  @Input() post: Post;
  @Output() public delete = new EventEmitter();

  constructor(private postService: PostService, private toastrService: ToastrService) {}

  ngOnInit() {}
  public onDelete() {
    if (confirm('Do you really want to delete this publication?')) {
      this.postService.deletePost(this.post.id).subscribe(() => this.delete.emit());
    }
  }

  public publish() {
    console.log('publish');
    if (confirm('Do you want to publish this post?')) {
      this.post.published = true;
      this.postService
        .updatePost(this.post)
        .subscribe(() => this.toastrService.success('Post published'));
    }
  }

  public duplicate() {
    this.postService.duplicate(this.post).subscribe(() => {
      this.toastrService.success('Post duplicated successfully');
      this.delete.emit();
    });
  }
}
