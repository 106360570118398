import { Directive, Input, OnInit } from '@angular/core';
import { TitleService } from '../../services/core/title.service';

@Directive({
  selector: '[appTitle]'
})
export class TitleDirective implements OnInit {
  @Input() pageTitle;
  constructor(private titleService: TitleService) {}

  ngOnInit(): void {
    this.titleService.title = this.pageTitle;
  }
}
