import { OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
var PublicationsPreviewComponent = /** @class */ (function () {
    function PublicationsPreviewComponent(postService) {
        this.postService = postService;
    }
    PublicationsPreviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setNavShapePosition();
        this.postService
            .getPostsPaginate()
            .pipe(take(1))
            .subscribe(function (e) { return (_this.posts = e); });
    };
    Object.defineProperty(PublicationsPreviewComponent.prototype, "visiblePosts", {
        get: function () {
            if (!this.posts)
                return [];
            return this.posts.filter(function (e) { return e.published; });
        },
        enumerable: true,
        configurable: true
    });
    PublicationsPreviewComponent.prototype.setNavShapePosition = function () {
        if (typeof window === 'undefined') {
            return;
        }
        var sectionPosition = document.querySelector('.first-section').getBoundingClientRect()
            .top;
        this.backgroundPosition = sectionPosition * -0.9 + 125;
    };
    PublicationsPreviewComponent.prototype.onResize = function () {
        this.setNavShapePosition();
    };
    // Scroll Listener
    PublicationsPreviewComponent.prototype.onScroll = function () {
        this.setNavShapePosition();
    };
    return PublicationsPreviewComponent;
}());
export { PublicationsPreviewComponent };
