import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BugReportService } from '../../../../services/core/dashboard/bug-report/bug-report.service';

@Component({
  selector: 'app-bugreport-form',
  templateUrl: './bugreport-form.component.html',
  styleUrls: ['./bugreport-form.component.styl']
})
export class BugReportFormComponent implements OnInit {
  public bugReportForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private bugReportService: BugReportService) {}

  ngOnInit() {
    this.bugReportForm = this.formBuilder.group({
      subject: ['', [Validators.required]],
      description: ['', []],
      message: ['', [Validators.required]]
    });
  }

  public submit() {
    if (!this.bugReportForm.invalid) {
      this.bugReportService.sendReport(this.bugReportForm.value);
    }
  }

  get subject() {
    return this.bugReportForm.get('subject');
  }
  get description() {
    return this.bugReportForm.get('description');
  }
  get message() {
    return this.bugReportForm.get('message');
  }
}
