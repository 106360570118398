<form [formGroup]="bugReportForm" (ngSubmit)="submit()" appTitle pageTitle="Bug report">
  <label>
    <div class="caution">
      <h6>
        {{'cms.core.bugreport.info' | translate}}
      </h6>
    </div>
  </label>


  <label>
    <div class="caution">
      <h6>{{'cms.core.bugreport.contact' | translate}}
        <br>info@edutec.lu
        <br>+352 691 666 666</h6>
    </div>
  </label>
  <label>
    {{ 'cms.core.bugreport.subject' | translate}}
    <input type="text" placeholder="{{ 'cms.core.bugreport.subject' | translate}}" formControlName="subject"/><br/>
  </label>
  <label>
    {{ 'cms.core.bugreport.reproduce' | translate}}
    <input type="text" placeholder="{{ 'cms.core.bugreport.reproduce' | translate}}" formControlName="description"/><br/>
  </label>
  <label>
    {{ 'cms.core.bugreport.reproduce' | translate}} *
    <textarea cols="30" rows="10" formControlName="message" placeholder="{{ 'cms.core.bugreport.message' | translate}}"></textarea><br/>
  </label>
  <button type="submit">
    {{ 'cms.core.bugreport.submit' | translate}}
  </button>
</form>
