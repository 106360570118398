/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tags.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/content/single-tag/single-tag.component.ngfactory";
import * as i3 from "../../../components/content/single-tag/single-tag.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../directives/core/title.directive";
import * as i6 from "../../../services/core/title.service";
import * as i7 from "@angular/common";
import * as i8 from "./tags.component";
import * as i9 from "../../../services/content/tag/tag.service";
import * as i10 from "ngx-toastr";
var styles_TagsComponent = [i0.styles];
var RenderType_TagsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagsComponent, data: {} });
export { RenderType_TagsComponent as RenderType_TagsComponent };
function View_TagsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-single-tag", [], null, [[null, "update"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("update" === en)) {
        var pd_0 = (_co.updateTag($event) !== false);
        ad = (pd_0 && ad);
    } if (("delete" === en)) {
        var pd_1 = (_co.deleteTag($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SingleTagComponent_0, i2.RenderType_SingleTagComponent)), i1.ɵdid(1, 114688, null, 0, i3.SingleTagComponent, [i4.FormBuilder], { tag: [0, "tag"] }, { delete: "delete", update: "update" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TagsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "section", [["appTitle", ""], ["pageTitle", "Tags"]], null, null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i5.TitleDirective, [i6.TitleService], { pageTitle: [0, "pageTitle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TagsComponent_1)), i1.ɵdid(3, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 9, ":svg:svg", [["class", "cms-button-text-primary"], ["viewBox", "0 0 211.97 211.97"], ["xmlns", "http://www.w3.org/2000/svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.add() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, ":svg:defs", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, ":svg:style", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [".cls-1{fill:#85CDDB;}.cls-2{fill:#fff;}"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, ":svg:title", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["addicon"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, ":svg:g", [["data-name", "Ebene 2"], ["id", "Ebene_2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, ":svg:g", [["id", "Background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, ":svg:circle", [["class", "cls-1"], ["cx", "105.99"], ["cy", "105.99"], ["r", "105.99"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, ":svg:path", [["class", "cls-2"], ["d", "M68.52,101.61h32.92v-33h9.09v33h32.92v9H110.53v32.71h-9.09V110.64H68.52Z"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Tags"; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.tag$)); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TagsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tags", [], null, null, null, View_TagsComponent_0, RenderType_TagsComponent)), i1.ɵdid(1, 114688, null, 0, i8.TagsComponent, [i9.TagService, i10.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TagsComponentNgFactory = i1.ɵccf("app-tags", i8.TagsComponent, View_TagsComponent_Host_0, {}, {}, []);
export { TagsComponentNgFactory as TagsComponentNgFactory };
