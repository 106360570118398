import { environment } from '../../../../../environments/environment';
import { take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(httpClient) {
        this.httpClient = httpClient;
    }
    UserService.prototype.getAll = function () {
        return this.httpClient.get(environment.api + '/api/user/').pipe(take(1));
    };
    UserService.prototype.delete = function (id) {
        return this.httpClient
            .delete("/api/user/" + id)
            .pipe(take(1))
            .subscribe();
    };
    UserService.prototype.create = function (userDto) {
        return this.httpClient.post(environment.api + '/api/auth/register/', userDto).pipe(take(1));
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
