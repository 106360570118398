/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-profile.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../directives/core/title.directive";
import * as i3 from "../../../services/core/title.service";
import * as i4 from "../../../components/core/user-profile/profile-details/profile-details.component.ngfactory";
import * as i5 from "../../../components/core/user-profile/profile-details/profile-details.component";
import * as i6 from "../../../components/core/user-profile/password-reset/password-reset.component.ngfactory";
import * as i7 from "../../../components/core/user-profile/password-reset/password-reset.component";
import * as i8 from "@angular/common/http";
import * as i9 from "ngx-toastr";
import * as i10 from "./user-profile.component";
var styles_UserProfileComponent = [i0.styles];
var RenderType_UserProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserProfileComponent, data: {} });
export { RenderType_UserProfileComponent as RenderType_UserProfileComponent };
export function View_UserProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["appTitle", ""], ["pageTitle", "Profile"]], null, null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.TitleDirective, [i3.TitleService], { pageTitle: [0, "pageTitle"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-profile-details", [], null, null, null, i4.View_ProfileDetailsComponent_0, i4.RenderType_ProfileDetailsComponent)), i1.ɵdid(3, 114688, null, 0, i5.ProfileDetailsComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-password-reset", [], null, null, null, i6.View_PasswordResetComponent_0, i6.RenderType_PasswordResetComponent)), i1.ɵdid(5, 114688, null, 0, i7.PasswordResetComponent, [i8.HttpClient, i9.ToastrService], null, null)], function (_ck, _v) { var currVal_0 = "Profile"; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_UserProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-profile", [], null, null, null, View_UserProfileComponent_0, RenderType_UserProfileComponent)), i1.ɵdid(1, 114688, null, 0, i10.UserProfileComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserProfileComponentNgFactory = i1.ɵccf("app-user-profile", i10.UserProfileComponent, View_UserProfileComponent_Host_0, {}, {}, []);
export { UserProfileComponentNgFactory as UserProfileComponentNgFactory };
