import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var BugReportFormComponent = /** @class */ (function () {
    function BugReportFormComponent(formBuilder, bugReportService) {
        this.formBuilder = formBuilder;
        this.bugReportService = bugReportService;
    }
    BugReportFormComponent.prototype.ngOnInit = function () {
        this.bugReportForm = this.formBuilder.group({
            subject: ['', [Validators.required]],
            description: ['', []],
            message: ['', [Validators.required]]
        });
    };
    BugReportFormComponent.prototype.submit = function () {
        if (!this.bugReportForm.invalid) {
            this.bugReportService.sendReport(this.bugReportForm.value);
        }
    };
    Object.defineProperty(BugReportFormComponent.prototype, "subject", {
        get: function () {
            return this.bugReportForm.get('subject');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BugReportFormComponent.prototype, "description", {
        get: function () {
            return this.bugReportForm.get('description');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BugReportFormComponent.prototype, "message", {
        get: function () {
            return this.bugReportForm.get('message');
        },
        enumerable: true,
        configurable: true
    });
    return BugReportFormComponent;
}());
export { BugReportFormComponent };
