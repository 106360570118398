<nav class="navbar">
    <button (click)="openNav()" name="mobileNavigation" [ngClass]="{'is-active': navOpen}"
            class="hamburger hamburger--spin" type="button">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
    </button>
    <div class="nav-slogan">
            <img (click)="resetFragment($event)" alt="slogan" src="/assets/img/landing/sloganNav.svg">
    </div>
    <div class="logos">
        <a href="http://www.upfoundation.lu/" target="_blank"><img src="/assets/img/landing/foundationlogo.svg" alt="logo"></a>
        <a href="http://www.edutec.lu" target="_blank"><img src="/assets/img/landing/eduteclogo.svg" alt="logo"></a>
    </div>
</nav>

<section class="nav-modal" [ngClass]="{'showNavModal': navOpen}">
    <ul>
        <li><a (click)="openNav()" [routerLink]="['/']">Accueil</a></li>
        <li><a (click)="openNav()" [routerLink]="['/posts']">Publications</a></li>
        <li><a (click)="openNav()" href="http://upfoundation.lu/conditions-generales/" target="_blank">Mentions légales</a></li>
        <li><a (click)="openNav()" href="http://upfoundation.lu/donnees-personnelles/" target="_blank">Données personnelles</a></li>
    </ul>
</section>
