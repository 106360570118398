/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-management.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/core/user-management/user-list/user-list.component.ngfactory";
import * as i3 from "../../../components/core/user-management/user-list/user-list.component";
import * as i4 from "../../../services/core/authentication/user.service";
import * as i5 from "./user-management.component";
var styles_UserManagementComponent = [i0.styles];
var RenderType_UserManagementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserManagementComponent, data: {} });
export { RenderType_UserManagementComponent as RenderType_UserManagementComponent };
export function View_UserManagementComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-list", [], null, null, null, i2.View_UserListComponent_0, i2.RenderType_UserListComponent)), i1.ɵdid(1, 114688, null, 0, i3.UserListComponent, [i4.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_UserManagementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-management", [], null, null, null, View_UserManagementComponent_0, RenderType_UserManagementComponent)), i1.ɵdid(1, 114688, null, 0, i5.UserManagementComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserManagementComponentNgFactory = i1.ɵccf("app-user-management", i5.UserManagementComponent, View_UserManagementComponent_Host_0, {}, {}, []);
export { UserManagementComponentNgFactory as UserManagementComponentNgFactory };
