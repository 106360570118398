import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/core/login/login.component';
import { DashboardComponent } from './pages/core/dashboard/dashboard.component';
import { AuthGuard } from './guards/auth.guard';
import { LogsComponent } from './pages/core/logs/logs.component';
import { UserManagementComponent } from './pages/core/user-management/user-management.component';
import { BugreportComponent } from './pages/core/bugreport/bugreport.component';
import { UserProfileComponent } from './pages/core/user-profile/user-profile.component';
import { CmsLayoutComponent } from './layouts/cms-layout/cms-layout.component';
import { ContentComponent } from './pages/content/content/content.component';
import { PublicationsComponent } from './pages/content/publications/publications.component';
import { SingleTagComponent } from './components/content/single-tag/single-tag.component';
import { SinglePublicationComponent } from './components/content/single-publication/single-publication.component';
import { TagNavComponent } from './components/content/tag-nav/tag-nav.component';
import { TagsComponent } from './pages/content/tags/tags.component';

const routes: Routes = [
  { path: 'auth', children: [{ path: 'login', component: LoginComponent, pathMatch: 'full' }] },
  {
    path: 'cms',
    component: CmsLayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard]
      },
      {
        path: 'users',
        component: UserManagementComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard]
      },
      {
        path: 'bugreport',
        component: BugreportComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        component: UserProfileComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard]
      },
      {
        path: 'logs',
        component: LogsComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard]
      },
      {
        path: 'tags',
        component: TagsComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard]
      },
      {
        path: 'post/:id',
        component: ContentComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'publications',
        component: PublicationsComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard]
      },
      {
        path: 'single-tag',
        component: SingleTagComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard]
      },
      {
        path: 'Tagnav',
        component: TagNavComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard]
      }
    ]
  },
  { path: '', redirectTo: '/cms/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CmsRoutingModule {}
