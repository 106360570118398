import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag } from '../../../models/content/tag';
import { TagService } from '../../../services/content/tag/tag.service';

@Component({
  selector: 'app-tag-nav',
  templateUrl: './tag-nav.component.html',
  styleUrls: ['./tag-nav.component.styl']
})
export class TagNavComponent implements OnInit {
  public tags$: Observable<Tag[]>;
  constructor(private tagService: TagService) { }

  ngOnInit() {
    this.tags$ = this.tagService.getTags();
  }

}
