/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./navigation.component";
var styles_NavigationComponent = [i0.styles];
var RenderType_NavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationComponent, data: {} });
export { RenderType_NavigationComponent as RenderType_NavigationComponent };
export function View_NavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "nav", [["class", "navbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "button", [["class", "hamburger hamburger--spin"], ["name", "mobileNavigation"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "is-active": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "hamburger-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "hamburger-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "nav-slogan"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "slogan"], ["src", "/assets/img/landing/sloganNav.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetFragment($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "logos"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["href", "http://www.upfoundation.lu/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["alt", "logo"], ["src", "/assets/img/landing/foundationlogo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["href", "http://www.edutec.lu"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["alt", "logo"], ["src", "/assets/img/landing/eduteclogo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 20, "section", [["class", "nav-modal"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(16, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(17, { "showNavModal": 0 }), (_l()(), i1.ɵeld(18, 0, null, null, 16, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.openNav() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(22, 1), (_l()(), i1.ɵted(-1, null, ["Accueil"])), (_l()(), i1.ɵeld(24, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 26).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.openNav() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(27, 1), (_l()(), i1.ɵted(-1, null, ["Publications"])), (_l()(), i1.ɵeld(29, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "a", [["href", "http://upfoundation.lu/conditions-generales/"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Mentions l\u00E9gales"])), (_l()(), i1.ɵeld(32, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 1, "a", [["href", "http://upfoundation.lu/donnees-personnelles/"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Donn\u00E9es personnelles"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "hamburger hamburger--spin"; var currVal_1 = _ck(_v, 4, 0, _co.navOpen); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "nav-modal"; var currVal_3 = _ck(_v, 17, 0, _co.navOpen); _ck(_v, 16, 0, currVal_2, currVal_3); var currVal_6 = _ck(_v, 22, 0, "/"); _ck(_v, 21, 0, currVal_6); var currVal_9 = _ck(_v, 27, 0, "/posts"); _ck(_v, 26, 0, currVal_9); }, function (_ck, _v) { var currVal_4 = i1.ɵnov(_v, 21).target; var currVal_5 = i1.ɵnov(_v, 21).href; _ck(_v, 20, 0, currVal_4, currVal_5); var currVal_7 = i1.ɵnov(_v, 26).target; var currVal_8 = i1.ɵnov(_v, 26).href; _ck(_v, 25, 0, currVal_7, currVal_8); }); }
export function View_NavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation", [], null, null, null, View_NavigationComponent_0, RenderType_NavigationComponent)), i1.ɵdid(1, 114688, null, 0, i4.NavigationComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavigationComponentNgFactory = i1.ɵccf("app-navigation", i4.NavigationComponent, View_NavigationComponent_Host_0, {}, {}, []);
export { NavigationComponentNgFactory as NavigationComponentNgFactory };
