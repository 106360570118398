/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./content.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/content/single-content/single-content.component.ngfactory";
import * as i3 from "../../../components/content/single-content/single-content.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../services/content/tag/tag.service";
import * as i6 from "../../../services/content/post/post.service";
import * as i7 from "@angular/router";
import * as i8 from "ngx-toastr";
import * as i9 from "./content.component";
var styles_ContentComponent = [i0.styles];
var RenderType_ContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentComponent, data: {} });
export { RenderType_ContentComponent as RenderType_ContentComponent };
export function View_ContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-single-content", [], null, null, null, i2.View_SingleContentComponent_0, i2.RenderType_SingleContentComponent)), i1.ɵdid(1, 114688, null, 0, i3.SingleContentComponent, [i4.FormBuilder, i5.TagService, i6.PostService, i7.ActivatedRoute, i8.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-content", [], null, null, null, View_ContentComponent_0, RenderType_ContentComponent)), i1.ɵdid(1, 114688, null, 0, i9.ContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContentComponentNgFactory = i1.ɵccf("app-content", i9.ContentComponent, View_ContentComponent_Host_0, {}, {}, []);
export { ContentComponentNgFactory as ContentComponentNgFactory };
