import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private privateTitle = '';
  constructor(public titleService: Title) {}

  public set title(value: string) {
    this.privateTitle = value;
    this.titleService.setTitle(`Bildung eemol anescht CMS -${value}`);
  }
  public get title() {
    return this.privateTitle;
  }
}
