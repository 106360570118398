<div class="sidebar-nav">
  <span class="read-more">more</span>
  <ul class="sidebar-nav__nav">
    <li>
      <a [routerLink]="['/cms/dashboard']" id="dashboard-container" routerLinkActive="active">
        <svg xmlns="http://www.w3.org/2000/svg" width="18.06mm" height="14.41mm" viewBox="0 0 51.21 40.86">
          <title>dashboardIcon</title>
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Ebene_1-2" data-name="Ebene 1">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1-2">
                  <g id="rU3K9v">
                    <path class="gcls-1"
                          d="M27.81,4.53V7.25a2.1,2.1,0,1,1-4.2,0V4.49a21.59,21.59,0,0,0-16,9.91l2.59,1.24a2.17,2.17,0,0,1-1.38,4.08,2.45,2.45,0,0,1-.45-.17c-.86-.38-1.72-.82-2.58-1.22-2.3,3.51-1.5,13.85,1.3,17.43l2.31-1.12a2.15,2.15,0,0,1,2.82,2.88,2.67,2.67,0,0,1-1.05,1c-1.28.7-2.61,1.31-3.91,1.94a2.11,2.11,0,0,1-2.91-.64l-.06-.1A24.76,24.76,0,0,1,.08,23.88,24.62,24.62,0,0,1,12.42,3.82,24.82,24.82,0,0,1,36.05,2.28a2.1,2.1,0,0,1,1.37,2.3,2.11,2.11,0,0,1-1.81,1.85,2.9,2.9,0,0,1-1.3-.21,22.44,22.44,0,0,0-5.85-1.64Z"/>
                    <path class="gcls-1"
                          d="M26.07,31.78a6.31,6.31,0,0,1-6.19-4.05A6,6,0,0,1,21.8,21c2.75-2.1,5.61-4.21,8.41-6.31,3.47-2.44,7-4.79,10.51-7.21a2.25,2.25,0,0,1,1.66-.46,2.11,2.11,0,0,1,1.94,2.26,2.19,2.19,0,0,1-.51,1.21c-.8,1.22-1.64,2.44-2.5,3.63L31.09,28.69c-.21.3-.44.57-.69.87A5.8,5.8,0,0,1,26.07,31.78ZM33.43,18h0l-.3.19-6,4.2c-.87.63-1.75,1.26-2.57,2a1.77,1.77,0,0,0-.16,2.49c.05.06.11.11.16.16a1.79,1.79,0,0,0,2.53,0l.1-.11a6.21,6.21,0,0,0,.52-.63l5.41-7.76Z"/>
                    <path class="gcls-1"
                          d="M44.23,35.86A17.65,17.65,0,0,0,45.89,32a21,21,0,0,0-.84-15,2.16,2.16,0,1,1,4-1.74,25.09,25.09,0,0,1,2.1,8A25.36,25.36,0,0,1,46.9,39.69a2.11,2.11,0,0,1-2.82,1l-.18-.11L40,38.68a2.16,2.16,0,1,1,1.87-3.89h0Z"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </a>
    </li>
    <li>
      <a id="lang-container">
        <svg (click)="openTranslationModal($event)" xmlns="http://www.w3.org/2000/svg" width="35.17" height="30" viewBox="0 0 35.17 30">
          <title>naguageIcon</title>
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Ebene_1-2" data-name="Ebene 1">
              <polygon class="cs-1" points="22.92 20.06 25.7 20.06 24.31 16.3 22.92 20.06"/>
              <path class="cs-1"
                    d="M24.31,8.28A10.92,10.92,0,0,0,20.42,9c0,.2,0,.39,0,.58a10.86,10.86,0,0,1-7,10.14A10.86,10.86,0,1,0,24.31,8.28Zm3.93,16.5a.8.8,0,0,1-.26,0,.74.74,0,0,1-.69-.48l-1-2.82H22.37l-1,2.82a.74.74,0,0,1-.95.43.73.73,0,0,1-.43-.94l3.67-9.91a.74.74,0,0,1,1.38,0l3.67,9.91A.73.73,0,0,1,28.24,24.78Z"/>
              <path class="cs-1"
                    d="M19.14,9.57a9.57,9.57,0,1,0-9.57,9.57A9.57,9.57,0,0,0,19.14,9.57ZM5.76,14.81a.64.64,0,0,1-.18-1.25A10.13,10.13,0,0,0,8.78,12,13,13,0,0,1,7.15,10.1a.64.64,0,0,1,.14-.89.65.65,0,0,1,.89.14,11.66,11.66,0,0,0,1.59,1.78,14.29,14.29,0,0,0,2.62-3.31H3.85a.63.63,0,0,1-.63-.63.64.64,0,0,1,.63-.64H8.93V5a.64.64,0,0,1,.64-.63A.63.63,0,0,1,10.2,5V6.55h5.09a.64.64,0,0,1,.63.64.63.63,0,0,1-.63.63H13.84A15,15,0,0,1,10.77,12a10.68,10.68,0,0,0,3.09,1.61.64.64,0,0,1-.16,1.25l-.17,0a12.24,12.24,0,0,1-3.75-2,11.52,11.52,0,0,1-3.84,2A.57.57,0,0,1,5.76,14.81Z"/>
              <polygon class="cs-1"
                       points="8.22 22.5 9.11 23.39 4.97 23.39 4.97 24.13 9.11 24.13 8.22 25.02 9.29 25.02 10.55 23.76 9.29 22.5 8.22 22.5"/>
              <polygon class="cs-1"
                       points="22.98 5.93 22.09 5.04 26.23 5.04 26.23 4.3 22.09 4.3 22.98 3.41 21.91 3.41 20.65 4.67 21.91 5.93 22.98 5.93"/>
            </g>
          </g>
        </svg>
      </a>
    </li>
    <li>
      <a id="user-management-container" [routerLink]="['/cms/users']">
        <svg xmlns="http://www.w3.org/2000/svg" width="41.98" height="21.96" viewBox="0 0 41.98 21.96">
          <title>GroupeIcon</title>
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Ebene_1-2" data-name="Ebene 1">
              <g class="cls-1">
                <circle class="cs-2" cx="32.2" cy="7.08" r="5.59"/>
                <path class="cs-2"
                      d="M42,18.23c0-2.67-2.49-5-6-5.93a6.43,6.43,0,0,1-7.51,0c-3.54,1-6,3.26-6,5.93a4.49,4.49,0,0,0,.6,2.23H41.37A4.5,4.5,0,0,0,42,18.23Z"/>
              </g>
              <g class="cls-1">
                <circle class="cs-2" cx="9.78" cy="7.08" r="5.59"/>
                <path class="cs-2"
                      d="M19.56,18.23c0-2.67-2.48-5-6-5.93A6.43,6.43,0,0,1,6,12.3c-3.54,1-6,3.26-6,5.93a4.4,4.4,0,0,0,.61,2.23H19A4.49,4.49,0,0,0,19.56,18.23Z"/>
              </g>
              <circle class="cs-2" cx="20.91" cy="6.47" r="6.47"/>
              <path class="cs-2"
                    d="M32.24,19.38c0-3.1-2.88-5.75-7-6.87a7.46,7.46,0,0,1-8.7,0c-4.09,1.12-7,3.77-7,6.87A5.14,5.14,0,0,0,10.29,22H31.54A5.23,5.23,0,0,0,32.24,19.38Z"/>
            </g>
          </g>
        </svg>
      </a>
    </li>
    <li>
      <a id="profile-container" [routerLink]="['/cms/profile']">
        <svg xmlns="http://www.w3.org/2000/svg" width="25.84" height="25.05" viewBox="0 0 25.84 25.05">
          <title>profileIcon</title>
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Ebene_1-2" data-name="Ebene 1">
              <circle class="clss-1" cx="12.92" cy="7.38" r="7.38"/>
              <path class="clss-1"
                    d="M25.84,22.11c0-3.54-3.28-6.56-8-7.84a8.49,8.49,0,0,1-9.92,0c-4.67,1.28-8,4.3-8,7.84a5.9,5.9,0,0,0,.8,2.94H25A5.9,5.9,0,0,0,25.84,22.11Z"/>
            </g>
          </g>
        </svg>
      </a>
    </li>
    <li>
      <a id="log-container" [routerLink]="['/cms/logs']">
        <svg xmlns="http://www.w3.org/2000/svg" width="31.47" height="18.22" viewBox="0 0 31.47 18.22">
          <title>logIcon</title>
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Ebene_1-2" data-name="Ebene 1">
              <rect class="clss-1" x="4.33" width="27.14" height="4.27"/>
              <rect class="clss-1" x="4.33" y="6.98" width="27.14" height="4.27"/>
              <rect class="clss-1" x="4.33" y="13.96" width="27.14" height="4.27"/>
              <path class="clss-1" d="M1.17,1h0l-.63.34L.43.94l.8-.43h.42V4.16H1.17Z"/>
              <path class="clss-1"
                    d="M0,11.14v-.3l.38-.38c.94-.89,1.36-1.36,1.36-1.91A.65.65,0,0,0,1,7.84a1.27,1.27,0,0,0-.78.31L.11,7.8a1.61,1.61,0,0,1,1-.37A1,1,0,0,1,2.26,8.49c0,.67-.49,1.22-1.26,2l-.29.27H2.34v.41Z"/>
              <path class="clss-1"
                    d="M.14,17.16a1.59,1.59,0,0,0,.8.23c.64,0,.83-.4.83-.7,0-.52-.47-.73-.95-.73H.55v-.37H.82c.36,0,.82-.19.82-.62s-.19-.55-.64-.55a1.37,1.37,0,0,0-.73.24L.14,14.3a1.73,1.73,0,0,1,1-.28c.72,0,1.05.43,1.05.87a.91.91,0,0,1-.68.86h0a1,1,0,0,1,.82.94c0,.58-.46,1.09-1.33,1.09A1.84,1.84,0,0,1,0,17.54Z"/>
            </g>
          </g>
        </svg>
      </a>
    </li>
    <li>
      <a id="bug-container" [routerLink]="['/cms/bugreport']">
        <svg xmlns="http://www.w3.org/2000/svg" width="32.91" height="33.89" viewBox="0 0 32.91 33.89">
          <title>bugreportIcon</title>
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Ebene_1-2" data-name="Ebene 1">
              <path class="clssss-1"
                    d="M32.66,25.08l-1.44-.73a7.6,7.6,0,0,1-2.31-2.06L27,19.48a3.71,3.71,0,0,0-2.33-1.69H23.8a3.72,3.72,0,0,1,.87-2.19c.5-.45.2-1.24-.66-1.75l-2.26-1.32.86-1a2.5,2.5,0,0,1,2.28-.58l1.19.29A6.11,6.11,0,0,0,29.45,11l1.3-.58a.46.46,0,0,0-.37-.84l-1.3.58a5.23,5.23,0,0,1-2.78.13L25.11,10a3.4,3.4,0,0,0-3.2.88l-1,1.17-1.09-.65a10.09,10.09,0,0,1-2.37-2s.07,0,.09-.06l.74-1.67a.22.22,0,0,0-.12-.29.23.23,0,0,0-.3.11l-.71,1.6a1,1,0,0,0-1.47,0L15,7.51a.23.23,0,0,0-.3-.11.22.22,0,0,0-.12.29l.74,1.67s.06,0,.09.06a10.3,10.3,0,0,1-2.37,2L12,12.07,11,10.9A3.4,3.4,0,0,0,7.8,10l-1.19.3a5.13,5.13,0,0,1-2.78-.13l-1.3-.58a.46.46,0,0,0-.37.84l1.3.58a6,6,0,0,0,3.37.17L8,10.91a2.48,2.48,0,0,1,2.27.58l.87,1L8.9,13.85c-.86.51-1.16,1.3-.66,1.75a3.79,3.79,0,0,1,.87,2.19H8.27a3.71,3.71,0,0,0-2.33,1.69L4,22.29a7.63,7.63,0,0,1-2.32,2.06l-1.43.73a.45.45,0,0,0-.2.6.45.45,0,0,0,.4.26.54.54,0,0,0,.21-.05l1.44-.72a8.4,8.4,0,0,0,2.64-2.36l2-2.81a3.15,3.15,0,0,1,1.58-1.3h.88v4.89H8.27c-.83,0-1.56,1.05-1.86,2.08L5.22,29.79A4.07,4.07,0,0,1,3.51,32l-1.43.71a.46.46,0,0,0-.21.61.46.46,0,0,0,.41.26.54.54,0,0,0,.21,0l1.43-.72A4.88,4.88,0,0,0,6.1,30l1.19-4.11c.27-.93.81-1.42,1-1.42h.88v1.73a4.67,4.67,0,0,0,1.36,3l4.58,4.11a2.09,2.09,0,0,0,2.72,0l4.59-4.11a4.71,4.71,0,0,0,1.36-3V24.51h.88c.17,0,.72.49,1,1.42L26.81,30A5,5,0,0,0,29,32.8l1.44.72a.46.46,0,0,0,.2,0,.46.46,0,0,0,.41-.26.45.45,0,0,0-.21-.61L29.4,32a4.07,4.07,0,0,1-1.71-2.2L26.5,25.67c-.3-1-1-2.08-1.86-2.08h-.88V18.7h.88A3.12,3.12,0,0,1,26.22,20l2,2.81a8.32,8.32,0,0,0,2.65,2.36l1.44.72a.46.46,0,0,0,.2.05.46.46,0,0,0,.41-.26.44.44,0,0,0-.21-.6Z"/>
              <path class="clssss-1"
                    d="M18.72,6.44a.23.23,0,0,0,.21-.13l1.22-2.78a.22.22,0,0,0-.11-.3.23.23,0,0,0-.3.11L18.51,6.12a.24.24,0,0,0,.11.31Z"/>
              <path class="clssss-1"
                    d="M20.57,2.28a.25.25,0,0,0,.21-.14L21.4.72a.35.35,0,0,0,.28-.34.38.38,0,0,0-.76,0A.44.44,0,0,0,21,.54L20.36,2a.22.22,0,0,0,.11.3l.1,0Z"/>
              <path class="clssss-1"
                    d="M14,6.31a.23.23,0,0,0,.21.13h.09a.25.25,0,0,0,.12-.31L13.17,3.34a.23.23,0,0,0-.3-.11.23.23,0,0,0-.12.3Z"/>
              <path class="clsss-1"
                    d="M11.51.72l.63,1.42a.22.22,0,0,0,.21.14l.09,0a.24.24,0,0,0,.12-.3L11.92.53A.3.3,0,0,0,12,.38.38.38,0,0,0,11.61,0a.39.39,0,0,0-.39.38.37.37,0,0,0,.29.34Z"/>
            </g>
          </g>
        </svg>
      </a>
    </li>
    <li>
      <a href="#" (click)="logout()">
        <svg xmlns="http://www.w3.org/2000/svg" width="26.89" height="29.82" viewBox="0 0 26.89 29.82">
          <title>logoutIcon</title>
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Ebene_1-2" data-name="Ebene 1">
              <path class="clddss-1" d="M17.45,3.54V7a10.22,10.22,0,1,1-8,0V3.54a13.45,13.45,0,1,0,8,0Z"/>
              <rect class="clsdssd-1" x="11.53" width="4.52" height="10.71"/>
            </g>
          </g>
        </svg>
      </a>
    </li>
  </ul>
</div>
<div class="language-modal" [style.display]="showTranslationModal ? 'flex' : 'none'">
  <h2>{{'cms.common.actions.chooselang' | translate}}</h2>
  <ul>
    <li>
      <Button (click)="setLanguage('fr')" class="cms-button">FR</Button>
    </li>
    <li>
      <Button (click)="setLanguage('en')" class="cms-button">EN</Button>
    </li>
    <li>
      <Button (click)="setLanguage('de')" class="cms-button">DE</Button>
    </li>
  </ul>
</div>
