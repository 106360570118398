/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bugreport.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/core/bugreport/bugreport-form/bugreport-form.component.ngfactory";
import * as i3 from "../../../components/core/bugreport/bugreport-form/bugreport-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../services/core/dashboard/bug-report/bug-report.service";
import * as i6 from "./bugreport.component";
var styles_BugreportComponent = [i0.styles];
var RenderType_BugreportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BugreportComponent, data: {} });
export { RenderType_BugreportComponent as RenderType_BugreportComponent };
export function View_BugreportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bugreport-form", [], null, null, null, i2.View_BugReportFormComponent_0, i2.RenderType_BugReportFormComponent)), i1.ɵdid(1, 114688, null, 0, i3.BugReportFormComponent, [i4.FormBuilder, i5.BugReportService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_BugreportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bugreport", [], null, null, null, View_BugreportComponent_0, RenderType_BugreportComponent)), i1.ɵdid(1, 114688, null, 0, i6.BugreportComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BugreportComponentNgFactory = i1.ɵccf("app-bugreport", i6.BugreportComponent, View_BugreportComponent_Host_0, {}, {}, []);
export { BugreportComponentNgFactory as BugreportComponentNgFactory };
