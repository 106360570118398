import { OnInit } from '@angular/core';
var LogsComponent = /** @class */ (function () {
    function LogsComponent(logService) {
        this.logService = logService;
        this.itemsPerPage = 5;
        this.page = 0;
    }
    LogsComponent.prototype.getQueryType = function (str) {
        return str.split(' ')[0];
    };
    Object.defineProperty(LogsComponent.prototype, "logs", {
        get: function () {
            var _this = this;
            if (this.selectedType !== 'null' && this.allLogs !== undefined) {
                return this.allLogs.filter(function (e) {
                    e.type = _this.selectedType;
                });
            }
            return this.allLogs;
        },
        enumerable: true,
        configurable: true
    });
    LogsComponent.prototype.setFilter = function (event) {
        this.selectedType = event.target.value;
    };
    LogsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.logService.getLogs(this.page, this.itemsPerPage).subscribe(function (logs) { return (_this.allLogs = logs); });
    };
    return LogsComponent;
}());
export { LogsComponent };
