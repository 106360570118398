/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/core/login-form/login-form.component.ngfactory";
import * as i3 from "../../../components/core/login-form/login-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../services/core/authentication/auth.service";
import * as i6 from "./login.component";
import * as i7 from "@angular/platform-browser";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-form", [], null, null, null, i2.View_LoginFormComponent_0, i2.RenderType_LoginFormComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoginFormComponent, [i4.FormBuilder, i5.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i6.LoginComponent, [i7.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i6.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
