<section>
  <h3>{{'cms.core.profile.changepassword' | translate}}</h3>
  <div>
    <label>
      {{'cms.core.profile.password' | translate}}
      <input [(ngModel)]="password" type="text" placeholder="Password"/>
    </label>
  </div>
  <div>
    <label>
      {{'cms.core.profile.validatepassword' | translate}}
      <input [(ngModel)]="verifyPassword" type="text" placeholder="Validate password"/>
    </label>
  </div>
  <button (click)="submit()">
    {{'cms.core.profile.changeinfo' | translate}}
  </button>
</section>
