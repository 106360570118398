import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Toast, ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../environments/environment';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.styl']
})
export class PasswordResetComponent implements OnInit {
  public password: any;
  public verifyPassword: any;
  constructor(private httpClient: HttpClient, private toastrService: ToastrService) {}

  ngOnInit() {}
  public submit() {
    const token = localStorage.getItem('token');
    const decodedToken = jwt_decode(token);
    if (this.password === this.verifyPassword) {
      this.httpClient
        .post(`${environment.api}/api/auth/password-change`, {
          username: decodedToken.username,
          password: this.password
        })
        .subscribe(() => this.toastrService.success('Password changed successfully'));
    } else {
      this.toastrService.error('Passwords do not match');
    }
  }
}
