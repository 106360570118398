<footer>
    <div class="logos">
        <a href="http://www.upfoundation.lu/" target="_blank"><img src="/assets/img/footer/foundationlogofooter.svg" alt="logo"></a>
        <a href="http://www.edutec.lu" target="_blank"><img src="/assets/img/landing/eduteclogo.svg" alt="logo"></a>
    </div>
    <div class="infos">
        29, rue Dicks L-4081 Esch-sur-Alzette&nbsp;&nbsp;|&nbsp;
        <a href="http://upfoundation.lu/conditions-generales/" target="_blank">Mentions légales</a>&nbsp;&nbsp;|&nbsp;
        <a href="http://upfoundation.lu/donnees-personnelles/" target="_blank">Données personnelles</a>
        <br>
        <a href="mailto:info@upfoundation.lu">info@upfoundation.lu</a>&nbsp;&nbsp;|&nbsp;
        <a href="mailto:info@edutec.lu">info@edutec.lu</a>
    </div>
    <a class="flamingoTab" href="http://www.flamingostudio.lu/" target="_blank"><img src="/assets/img/footer/flamingoTab.svg" alt="flamingo"></a>
</footer>
