import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Log } from '../../../../models/core/log';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  constructor(private httpClient: HttpClient) {}
  public getLogs(page: number, itemsPerPage: number): Observable<Log[]> {
    return this.httpClient.get<Log[]>(`/api/logs/${page}/${itemsPerPage}`);
  }
}
