/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./nav.component";
var styles_NavComponent = [i0.styles];
var RenderType_NavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavComponent, data: {} });
export { RenderType_NavComponent as RenderType_NavComponent };
export function View_NavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "nav", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 25, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "a", [["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 1), i1.ɵdid(6, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 6, "a", [["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 1), i1.ɵdid(15, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" Tags "])), (_l()(), i1.ɵeld(19, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 6, "a", [["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 671744, [[6, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(22, 1), i1.ɵdid(23, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" Publications "])), (_l()(), i1.ɵeld(27, 0, null, null, 0, "div", [["class", "border"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = _ck(_v, 5, 0, "/cms/dashboard"); _ck(_v, 4, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 6, 0, currVal_3); var currVal_7 = _ck(_v, 14, 0, "/cms/tags"); _ck(_v, 13, 0, currVal_7); var currVal_8 = "active"; _ck(_v, 15, 0, currVal_8); var currVal_11 = _ck(_v, 22, 0, "/cms/publications"); _ck(_v, 21, 0, currVal_11); var currVal_12 = "active"; _ck(_v, 23, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("cms.navigation.dashboard")); _ck(_v, 9, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 13).target; var currVal_6 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_5, currVal_6); var currVal_9 = i1.ɵnov(_v, 21).target; var currVal_10 = i1.ɵnov(_v, 21).href; _ck(_v, 20, 0, currVal_9, currVal_10); }); }
export function View_NavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav", [], null, null, null, View_NavComponent_0, RenderType_NavComponent)), i1.ɵdid(1, 114688, null, 0, i5.NavComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavComponentNgFactory = i1.ɵccf("app-nav", i5.NavComponent, View_NavComponent_Host_0, {}, {}, []);
export { NavComponentNgFactory as NavComponentNgFactory };
