import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.styl']
})
export class NavigationComponent implements OnInit {
  public activeClass: string;
  public navOpen: boolean;

  constructor(private router: Router) {}

  ngOnInit() {
    this.activeClass = 'home';
    this.navOpen = false;
  }

  public openNav() {
    this.navOpen = !this.navOpen;
    document.querySelector('body').style.overflow = this.navOpen ? 'hidden' : 'auto';
  }

  public resetFragment(event) {
    event.preventDefault();
    this.router.navigate(['/'], { fragment: 'home' }).then(() => this.router.navigate(['/']));
  }
}
