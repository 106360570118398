import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PostService = /** @class */ (function () {
    function PostService(httpClient) {
        this.httpClient = httpClient;
    }
    PostService.prototype.getPosts = function () {
        return this.httpClient.get(environment.api + "/api/post");
    };
    PostService.prototype.getPostsPaginate = function () {
        return this.httpClient.get(environment.api + "/api/post/0/4");
    };
    PostService.prototype.getPost = function (id) {
        return this.httpClient.get(environment.api + "/api/post/" + id);
    };
    PostService.prototype.updatePost = function (post) {
        return this.httpClient.put(environment.api + "/api/post/", post).pipe(take(1));
    };
    PostService.prototype.createPost = function () {
        return this.httpClient.post(environment.api + "/api/post/", { published: false });
    };
    PostService.prototype.duplicate = function (post) {
        var _this = this;
        return this.httpClient
            .get(environment.api + "/api/post/" + post.id)
            .pipe(switchMap(function (e) {
            delete e.id;
            e.published = false;
            return _this.httpClient.post(environment.api + "/api/post/", e);
        }))
            .pipe(take(1));
    };
    PostService.prototype.uploadImage = function (image, id) {
        var uploadData = new FormData();
        uploadData.append('image', image);
        return this.httpClient
            .post(environment.api + "/api/post/upload/" + id, uploadData)
            .pipe(take(1));
    };
    PostService.prototype.deletePost = function (id) {
        return this.httpClient.delete(environment.api + "/api/post/" + id).pipe(take(1));
    };
    PostService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PostService_Factory() { return new PostService(i0.ɵɵinject(i1.HttpClient)); }, token: PostService, providedIn: "root" });
    return PostService;
}());
export { PostService };
