import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrontendRoutingModule } from './frontend-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { LandingComponent } from './pages/landing/landing.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { PublicationsPreviewComponent } from './components/publications-preview/publications-preview.component';
import { FooterComponent } from './components/footer/footer.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { TermsComponent } from './pages/terms/terms.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PublicationCardComponent } from './components/publication-card/publication-card.component';
import { BlogComponent } from './pages/blog/blog.component';
import { MainComponent } from './layouts/main/main.component';
import { PostDetailComponent } from './pages/post-detail/post-detail.component';

@NgModule({
  declarations: [
    LandingComponent,
    NavigationComponent,
    PublicationsPreviewComponent,
    FooterComponent,
    ImpressumComponent,
    TermsComponent,
    PublicationCardComponent,
    BlogComponent,
    MainComponent,
    PostDetailComponent
  ],
  imports: [
    CommonModule,
    FrontendRoutingModule,
    FontAwesomeModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: []
})
export class FrontendModule {}
