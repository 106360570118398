<section>
  <h3> {{ 'cms.core.profile.personalinfo' | translate}} </h3>
  <div>
    <label>
      {{'cms.common.username' | translate}}
      <input type="text" placeholder="{{'cms.common.username' | translate}}" />
    </label>
  </div>
  <div>
    <label>
      {{'cms.core.profile.firstname' | translate}}
      <input type="text" placeholder="{{'cms.core.profile.firstname' | translate}}" />
    </label>
  </div>
  <div>
    <label>
      {{'cms.core.profile.lastname' | translate}}
      <input type="text" placeholder="{{'cms.core.profile.lastname' | translate}}" />
    </label>
  </div>
  <button type="submit">
    {{'cms.core.profile.changeinfo' | translate}}
  </button>
</section>
