import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private toastrService: ToastrService,
    private router: Router,
    private translateService: TranslateService
  ) {}
  public login(username: string, password: string) {
    this.httpClient
      .post(`${environment.api}/api/auth/login`, { username, password })
      .pipe(take(1))
      .subscribe(
        (result: any) => {
          localStorage.setItem('token', result.access_token);
          this.router.navigate(['cms/dashboard']);
        },
        error => {
          this.toastrService.error(
            this.translateService.instant('cms.core.messages.login.failure')
          );
        }
      );
  }
  public logOut() {
    localStorage.removeItem('token');
    this.router.navigate(['/cms/login']);
  }
  public getAuthToken(): string {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('token');
    }
    return undefined;
  }
  public isAuthenticated(): boolean {
    const helper = new JwtHelperService();
    const token = this.getAuthToken();
    if (!token) {
      return false;
    }
    return !helper.isTokenExpired(token);
  }
}
