import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var LoginFormComponent = /** @class */ (function () {
    function LoginFormComponent(formBuilder, authService) {
        this.formBuilder = formBuilder;
        this.authService = authService;
    }
    LoginFormComponent.prototype.ngOnInit = function () {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.min(5), Validators.required]],
            password: ['', [Validators.required]]
        });
    };
    LoginFormComponent.prototype.submit = function () {
        if (this.loginForm.valid) {
            this.authService.login(this.username.value, this.password.value);
        }
    };
    Object.defineProperty(LoginFormComponent.prototype, "username", {
        get: function () {
            return this.loginForm.get('username');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginFormComponent.prototype, "password", {
        get: function () {
            return this.loginForm.get('password');
        },
        enumerable: true,
        configurable: true
    });
    return LoginFormComponent;
}());
export { LoginFormComponent };
