import { JwtHelperService } from '@auth0/angular-jwt';
import { take } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-toastr";
import * as i3 from "@angular/router";
import * as i4 from "@ngx-translate/core";
var AuthService = /** @class */ (function () {
    function AuthService(httpClient, toastrService, router, translateService) {
        this.httpClient = httpClient;
        this.toastrService = toastrService;
        this.router = router;
        this.translateService = translateService;
    }
    AuthService.prototype.login = function (username, password) {
        var _this = this;
        this.httpClient
            .post(environment.api + "/api/auth/login", { username: username, password: password })
            .pipe(take(1))
            .subscribe(function (result) {
            localStorage.setItem('token', result.access_token);
            _this.router.navigate(['cms/dashboard']);
        }, function (error) {
            _this.toastrService.error(_this.translateService.instant('cms.core.messages.login.failure'));
        });
    };
    AuthService.prototype.logOut = function () {
        localStorage.removeItem('token');
        this.router.navigate(['/cms/login']);
    };
    AuthService.prototype.getAuthToken = function () {
        if (typeof window !== 'undefined') {
            return localStorage.getItem('token');
        }
        return undefined;
    };
    AuthService.prototype.isAuthenticated = function () {
        var helper = new JwtHelperService();
        var token = this.getAuthToken();
        if (!token) {
            return false;
        }
        return !helper.isTokenExpired(token);
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.TranslateService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
