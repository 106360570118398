/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cms-layout.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/core/shared/nav/nav.component.ngfactory";
import * as i3 from "../../components/core/shared/nav/nav.component";
import * as i4 from "../../components/core/shared/side-nav/side-nav.component.ngfactory";
import * as i5 from "../../components/core/shared/side-nav/side-nav.component";
import * as i6 from "../../services/core/authentication/auth.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/router";
import * as i9 from "./cms-layout.component";
import * as i10 from "../../services/core/title.service";
import * as i11 from "@angular/platform-browser";
var styles_CmsLayoutComponent = [i0.styles];
var RenderType_CmsLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CmsLayoutComponent, data: {} });
export { RenderType_CmsLayoutComponent as RenderType_CmsLayoutComponent };
export function View_CmsLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav", [], null, null, null, i2.View_NavComponent_0, i2.RenderType_NavComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-side-nav", [], null, null, null, i4.View_SideNavComponent_0, i4.RenderType_SideNavComponent)), i1.ɵdid(3, 114688, null, 0, i5.SideNavComponent, [i6.AuthService, i7.TranslateService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "vertical-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleService.title; _ck(_v, 8, 0, currVal_0); }); }
export function View_CmsLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cms-layout", [], null, null, null, View_CmsLayoutComponent_0, RenderType_CmsLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i9.CmsLayoutComponent, [i10.TitleService, i7.TranslateService, i11.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CmsLayoutComponentNgFactory = i1.ɵccf("app-cms-layout", i9.CmsLayoutComponent, View_CmsLayoutComponent_Host_0, {}, {}, []);
export { CmsLayoutComponentNgFactory as CmsLayoutComponentNgFactory };
