import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/core/authentication/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.styl']
})
export class SideNavComponent implements OnInit {
  public showTranslationModal: boolean;

  constructor(private authService: AuthService, private translateService: TranslateService) {}

  ngOnInit() {}

  public setLanguage(langCode: string) {
    this.translateService.use(langCode);
    if (typeof window !== 'undefined') {
      localStorage.setItem('language', langCode);
    }
    this.showTranslationModal = false;
  }

  public openTranslationModal(event) {
    event.preventDefault();
    this.showTranslationModal = true;
  }

  public logout() {
    this.authService.logOut();
  }
}
