<div appTitle pageTitle="Logs">
  <div class="control">
    <label>
      Log Level :
      <select (change)="setFilter($event)">
        <option selected value="null">Default</option>
        <option value="error">Error</option>
        <option value="warning">Info</option>
      </select>
    </label>
  </div>
	<div class="item" *ngFor="let log of logs" [ngClass]="{ 'error': log.type === 'error' }">
    <h1> {{ getQueryType(log.query) }} </h1>
    <h2> {{ log.createdAt | date:'medium' }} </h2>
    <pre><code [languages]="['sql']" [highlight]="log.query"></code></pre>
    <pre><code [languages]="['js']" [highlight]="log.parameters"></code></pre>
	</div>
</div>
