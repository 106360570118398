import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../services/core/authentication/user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.styl']
})
export class UserAddComponent implements OnInit {
  @Output() userAdded = new EventEmitter();
  public userAddFrom: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.userAddFrom = this.formBuilder.group({
      username: ['', [Validators.min(5), Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      password: ['', [Validators.required]],
      emailAddress: ['', [Validators.email]],
      mustChangePassword: [true, []]
    });
  }

  onSubmit(): void {
    if (this.userAddFrom.invalid) return;
    this.userService.create(this.userAddFrom.value).subscribe(
      () => {
        this.toastrService.success(
          this.translateService.instant('cms.core.usermanagement.messages.add.successful')
        );
        this.userAdded.emit();
      },
      () => {
        this.toastrService.error(
          this.translateService.instant('cms.core.usermanagement.messages.add.error')
        );
      }
    );
  }
}
