import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BugReportService {
  constructor(private httpClient: HttpClient) {}
  public sendReport(report) {
    this.httpClient
      .post(environment.api + '/api/bug-report', report)
      .pipe(take(1))
      .subscribe();
  }
}
