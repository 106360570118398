/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tag-nav.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tag-nav.component";
import * as i3 from "../../../services/content/tag/tag.service";
var styles_TagNavComponent = [i0.styles];
var RenderType_TagNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagNavComponent, data: {} });
export { RenderType_TagNavComponent as RenderType_TagNavComponent };
export function View_TagNavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["placeholder", "search"], ["type", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, ":svg:svg", [["class", "research"], ["viewBox", "0 0 21.66 21.66"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, ":svg:defs", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:style", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [".cls-1{fill:#85CDDB;}"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, ":svg:g", [["data-name", "Layer 2"], ["id", "Layer_2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, ":svg:g", [["data-name", "Layer 1"], ["id", "Layer_1-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, ":svg:path", [["class", "cls-1"], ["d", "M10.46,13.46l-1.3-1.29a5.42,5.42,0,1,1,3-3l1.3,1.29a7.13,7.13,0,1,0-3,3Z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, ":svg:path", [["class", "cls-1"], ["d", "M4,.72,5.27,2.06A5.41,5.41,0,1,1,2.18,5L.92,3.63A7.13,7.13,0,1,0,4,.72Z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, ":svg:rect", [["class", "cls-1"], ["height", "13.2"], ["transform", "translate(-6.58 15.87) rotate(-45)"], ["width", "3.17"], ["x", "14.28"], ["y", "9.28"]], null, null, null, null, null))], null, null); }
export function View_TagNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tag-nav", [], null, null, null, View_TagNavComponent_0, RenderType_TagNavComponent)), i1.ɵdid(1, 114688, null, 0, i2.TagNavComponent, [i3.TagService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TagNavComponentNgFactory = i1.ɵccf("app-tag-nav", i2.TagNavComponent, View_TagNavComponent_Host_0, {}, {}, []);
export { TagNavComponentNgFactory as TagNavComponentNgFactory };
