import { environment } from '../../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TagService = /** @class */ (function () {
    function TagService(httpClient) {
        this.httpClient = httpClient;
    }
    TagService.prototype.getTags = function () {
        return this.httpClient.get(environment.api + "/api/tag/");
    };
    TagService.prototype.updateTag = function (tag) {
        return this.httpClient.put("/api/tag/", tag);
    };
    TagService.prototype.createTag = function (tag) {
        return this.httpClient.post(environment.api + "/api/tag/", tag);
    };
    TagService.prototype.deleteTag = function (id) {
        return this.httpClient.delete("/api/tag/" + id);
    };
    TagService.prototype.listifyTags = function (tags) {
        if (tags === undefined)
            return [];
        return tags.map(function (item) {
            return { id: item.id, itemName: item.tag };
        });
    };
    TagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TagService_Factory() { return new TagService(i0.ɵɵinject(i1.HttpClient)); }, token: TagService, providedIn: "root" });
    return TagService;
}());
export { TagService };
