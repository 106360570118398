import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Post } from '../../../cms/models/content/post';

@Component({
  selector: 'app-publication-card',
  templateUrl: './publication-card.component.html',
  styleUrls: ['./publication-card.component.styl']
})
export class PublicationCardComponent implements OnInit {
  @Input() public post: Post;
  constructor() {}

  ngOnInit() {}
}
