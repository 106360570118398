import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../services/core/authentication/user.service';
import { User } from '../../../../services/core/authentication/user.model';
import { Observable } from 'rxjs';
import { ModalComponent } from '../../shared/modal/modal.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.styl']
})
export class UserListComponent implements OnInit {
  @ViewChild(ModalComponent, { static: false }) child: ModalComponent;

  public users$: Observable<User[]>;
  constructor(private userService: UserService) {}
  ngOnInit() {
    this.users$ = this.userService.getAll();
  }
  public openModal() {
    this.child.show();
  }

  public userAdded() {
    this.child.close();
    this.users$ = this.userService.getAll();
  }
  public delete(id: number) {
    if (confirm('Do you really want to delete this user?')) {
      this.userService.delete(id);
      this.users$ = this.userService.getAll();
    }
  }
}
