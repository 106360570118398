import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Tag } from '../../../models/content/tag';
import { TagService } from '../../../services/content/tag/tag.service';

@Component({
	selector: 'app-tags',
	templateUrl: './tags.component.html',
	styleUrls: ['./tags.component.styl'],
})
export class TagsComponent implements OnInit {
	public tag$: Observable<Tag[]>;

	constructor(private tagService: TagService, private toastrService: ToastrService) {
	}

	ngOnInit() {
		this.getTags();
	}

	public getTags() {
		this.tag$ = this.tagService.getTags();
	}

	public add() {
		this.tagService.createTag({tag: ''}).subscribe(
				() => {
					this.toastrService.success('Added successfully');
					this.getTags();
				},
				() => this.toastrService.error('Failed to add'),
		);
		this.getTags();
	}

	public updateTag(tag: Tag) {
		this.tagService.updateTag(tag).subscribe(
				() => this.toastrService.success('Updated successfully'),
				() => this.toastrService.error('Failed to update'),
		);
	}

	public deleteTag(id: number) {
		this.tagService.deleteTag(id).subscribe(
				() => {
					this.toastrService.success('Deleted successfully');
					this.getTags();
				},
				() => this.toastrService.error('Failed to delete'),
		);

	}

}
