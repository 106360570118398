import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Post } from '../../../models/content/post';
import { PostService } from '../../../services/content/post/post.service';
import { TagService } from '../../../services/content/tag/tag.service';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.styl']
})
export class PublicationsComponent implements OnInit {
  public posts: Post[];
  public tags = [];
  public searchInput = '';
  public selectedTags = [];
  constructor(
    private postService: PostService,
    private router: Router,
    private tagService: TagService
  ) {}

  ngOnInit() {
    this.getPosts();
    this.tagService.getTags().subscribe(e => (this.tags = this.tagService.listifyTags(e)));
  }

  public getPosts() {
    this.postService
      .getPosts()
      .pipe(take(1))
      .subscribe(e => (this.posts = e));
  }

  public searchedPosts() {
    if (!this.posts) return;
    if (this.searchInput === '') return this.posts;
    return this.posts.filter(
      e => e.title != null && e.title.toLocaleLowerCase().includes(this.searchInput.toLowerCase())
    );
  }

  public createPublication() {
    this.postService.createPost().subscribe(e => this.router.navigate([`/cms/post/${e}`]));
  }

  public onPublicationDelete() {
    this.getPosts();
  }
}
