<article>
    <div class="content">
        <div>
            <div class="card-head">
                <h2>{{post.title}}</h2>
                <span class="date">{{post.publishDate | date}}</span>
            </div>
            <p>{{post.excerpt}}</p>
            <a [routerLink]="['/post/' + post.id]">lire plus</a>
        </div>
    </div>
    <div class="image"
         [style.background-image]="'url(https://upfoundation.fra1.digitaloceanspaces.com/posts/' + post.coverImageUrl + ')'">
    </div>
</article>
