/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landing.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/publications-preview/publications-preview.component.ngfactory";
import * as i3 from "../../components/publications-preview/publications-preview.component";
import * as i4 from "../../../cms/services/content/post/post.service";
import * as i5 from "./landing.component";
var styles_LandingComponent = [i0.styles];
var RenderType_LandingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingComponent, data: {} });
export { RenderType_LandingComponent as RenderType_LandingComponent };
export function View_LandingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-publications-preview", [], null, [["window", "resize"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("window:scroll" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onScroll() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_PublicationsPreviewComponent_0, i2.RenderType_PublicationsPreviewComponent)), i1.ɵdid(1, 114688, null, 0, i3.PublicationsPreviewComponent, [i4.PostService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_LandingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landing", [], null, null, null, View_LandingComponent_0, RenderType_LandingComponent)), i1.ɵdid(1, 114688, null, 0, i5.LandingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LandingComponentNgFactory = i1.ɵccf("app-landing", i5.LandingComponent, View_LandingComponent_Host_0, {}, {}, []);
export { LandingComponentNgFactory as LandingComponentNgFactory };
