import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Toast, ToastrService } from 'ngx-toastr';
import { switchMap, take } from 'rxjs/operators';
import { Post } from '../../../models/content/post';
import { PostService } from '../../../services/content/post/post.service';
import { TagService } from '../../../services/content/tag/tag.service';

@Component({
  selector: 'app-single-content',
  templateUrl: './single-content.component.html',
  styleUrls: ['./single-content.component.styl']
})
export class SingleContentComponent implements OnInit {
  @Input() public backgroundImg;
  public previewUrl = '';
  public post: Post;
  public content: string;
  public selectedTags = [];
  public tags = [];
  public tinyConfig = {
    toolbar_sticky: true,
    height: 500,
    menubar: false,
    image_caption: true,
    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
    noneditable_noneditable_class: 'mceNonEditable',
    toolbar_mode: 'sliding',
    contextmenu: 'link image imagetools table',
    plugins: [
      // tslint:disable-next-line:max-line-length
      'preview paste searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr nonbreaking insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars'
    ],
    toolbar:
      // tslint:disable-next-line:max-line-length
      'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl'
  };
  public postForm: FormGroup;
  public dropdownSettings = {
    singleSelection: false,
    text: 'Select Tags',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true
  };

  constructor(
    public formBuilder: FormBuilder,
    private tagService: TagService,
    private postService: PostService,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap(param => this.postService.getPost(param.id)),
        take(1)
      )
      .subscribe(e => {
        this.post = e;
        this.selectedTags = this.tagService.listifyTags(this.post.tags);
        this.content = this.post.body;
        this.postForm.controls.title.setValue(this.post.title);
        this.postForm.controls.excerpt.setValue(this.post.excerpt);
        this.postForm.controls.link.setValue(this.post.linkedArticle);
        this.postForm.controls.publishedDate.setValue(this.post.publishDate);
        this.postForm.controls.published.setValue(this.post.published);
      });
    this.tagService
      .getTags()
      .pipe(take(1))
      .subscribe(e => {
        this.tags = this.tagService.listifyTags(e);
      });
    this.postForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      excerpt: ['', Validators.required],
      publishedDate: ['', Validators.required],
      published: [false],
      link: ['']
    });
  }

  public uploadEventHandler(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (ev: any) => {
        this.previewUrl = ev.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.postService
        .uploadImage(event.target.files[0], this.post.id)
        .subscribe(e => (this.post.coverImageUrl = e.coverImageUrl));
    }
  }

  public save() {
    const formData = {
      id: this.post.id,
      tags: this.selectedTags,
      title: this.postForm.controls.title.value,
      publishDate: this.postForm.controls.publishedDate.value,
      linkedArticle: this.postForm.controls.link.value,
      excerpt: this.postForm.controls.excerpt.value,
      body: this.content,
      published: this.postForm.controls.published.value,
      coverImageUrl: this.post.coverImageUrl
    };

    this.postService.updatePost(formData).subscribe(
      e => this.toastrService.success('Saved successfully'),
      e => this.toastrService.error('An error occured')
    );
  }
}
