/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../directives/core/title.directive";
import * as i3 from "../../../services/core/title.service";
import * as i4 from "./dashboard.component";
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
export function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "section", [["appTitle", ""], ["pageTitle", "Dashboard"]], null, null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.TitleDirective, [i3.TitleService], { pageTitle: [0, "pageTitle"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "statistics"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bildung eemol anescht"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Content Management System by "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["href", "https://www.edutec.lu/#home"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Edutec"]))], function (_ck, _v) { var currVal_0 = "Dashboard"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 114688, null, 0, i4.DashboardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardComponentNgFactory = i1.ɵccf("app-dashboard", i4.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };
