import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Post } from '../../../cms/models/content/post';
import { Tag } from '../../../cms/models/content/tag';
import { PostService } from '../../../cms/services/content/post/post.service';
import { TagService } from '../../../cms/services/content/tag/tag.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.styl'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('.0.25s ease-in', style({ opacity: '1' }))
      ])
    ])
  ]
})
export class BlogComponent implements OnInit {
  public posts: Post[];
  public tags: Tag[];
  public limit = 8;
  public filter = '';
  public search = '';

  constructor(
    private postService: PostService,
    private tagService: TagService,
    private route: ActivatedRoute
  ) {}

  public get visiblePosts() {
    if (!this.posts) return;
    return this.posts
      .filter(e => e.published)
      .filter(e => this.filter === '' || e.tags.filter(tag => tag.tag === this.filter).length > 0)
      .filter(e => this.search === '' || e.title.toLowerCase().includes(this.search.toLowerCase()));
  }

  ngOnInit() {
    this.postService.getPosts().subscribe(e => (this.posts = e));
    this.tagService.getTags().subscribe(e => (this.tags = e));
    this.route.params.subscribe(e => (this.filter = e.filter !== undefined ? e.filter : ''));
  }
}
