import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.styl']
})
export class ModalComponent implements OnInit {
  constructor() {}
  public hidden = true;
  ngOnInit() {}
  public close() {
    this.hidden = true;
  }
  public show() {
    this.hidden = false;
  }
}
