import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../services/core/title.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cms-layout',
  templateUrl: './cms-layout.component.html',
  styleUrls: ['./cms-layout.component.styl']
})
export class CmsLayoutComponent implements OnInit {
  constructor(
    public titleService: TitleService,
    private translate: TranslateService,
    private title: Title
  ) {
    if (typeof navigator === 'undefined') {
      return;
    }
    switch (navigator.language) {
      case 'fr':
        this.translate.setDefaultLang('fr');
        break;
      case 'lu':
        this.translate.setDefaultLang('lu');
        break;
      case 'de':
        this.translate.setDefaultLang('de');
        break;
      default:
        translate.setDefaultLang('en');
        break;
    }
    if (typeof window !== 'undefined' && localStorage.getItem('language') !== null) {
      translate.use(localStorage.getItem('language'));
    }
  }

  ngOnInit() {
    this.title.setTitle('Bildung eemol anescht - CMS');
  }
}
