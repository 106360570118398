import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Post } from '../../../models/content/post';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  constructor(private httpClient: HttpClient) {}
  public getPosts(): Observable<Post[]> {
    return this.httpClient.get<Post[]>(`${environment.api}/api/post`);
  }

  public getPostsPaginate(): Observable<Post[]> {
    return this.httpClient.get<Post[]>(`${environment.api}/api/post/0/4`);
  }

  public getPost(id: any) {
    return this.httpClient.get<Post>(`${environment.api}/api/post/${id}`);
  }

  public updatePost(post: Post) {
    return this.httpClient.put(`${environment.api}/api/post/`, post).pipe(take(1));
  }

  public createPost(): Observable<number> {
    return this.httpClient.post<number>(`${environment.api}/api/post/`, { published: false });
  }

  public duplicate(post: Post): Observable<number> {
    return this.httpClient
      .get<Post>(`${environment.api}/api/post/${post.id}`)
      .pipe(
        switchMap(e => {
          delete e.id;
          e.published = false;
          return this.httpClient.post<number>(`${environment.api}/api/post/`, e);
        })
      )
      .pipe(take(1));
  }

  public uploadImage(image: File, id: number): Observable<Post> {
    const uploadData = new FormData();
    uploadData.append('image', image);
    return this.httpClient
      .post<Post>(`${environment.api}/api/post/upload/${id}`, uploadData)
      .pipe(take(1));
  }

  public deletePost(id: any) {
    return this.httpClient.delete(`${environment.api}/api/post/${id}`).pipe(take(1));
  }
}
