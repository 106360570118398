import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LogsService = /** @class */ (function () {
    function LogsService(httpClient) {
        this.httpClient = httpClient;
    }
    LogsService.prototype.getLogs = function (page, itemsPerPage) {
        return this.httpClient.get("/api/logs/" + page + "/" + itemsPerPage);
    };
    LogsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LogsService_Factory() { return new LogsService(i0.ɵɵinject(i1.HttpClient)); }, token: LogsService, providedIn: "root" });
    return LogsService;
}());
export { LogsService };
