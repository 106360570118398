import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './layouts/main/main.component';
import { BlogComponent } from './pages/blog/blog.component';
import { LandingComponent } from './pages/landing/landing.component';
import { PostDetailComponent } from './pages/post-detail/post-detail.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    pathMatch: 'prefix',
    children: [
      { path: '', component: LandingComponent, pathMatch: 'prefix' },
      { path: 'posts', component: BlogComponent, pathMatch: 'full' },
      { path: 'post/:id', component: PostDetailComponent, pathMatch: 'prefix' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontendRoutingModule {}
